import {
  BuildingTypeOUT,
  DGNBPassportIN,
  PlaceDetailsOUT,
  ReportOUT,
} from "api-client";
import { AddEditBuildingForm } from "features/Building/AddEditBuilding";
import handleDgnbPassportCreate from "features/Building/BuildingMaterialResources/DGNB/handlers/handleDgnbCreate";
import handleDgnbPassportGetLatest from "features/Building/BuildingMaterialResources/DGNB/handlers/handleDgnbGetLatest";
import { UPCOMING_OPERATION_LIST } from "features/Building/consts.d";
import { makeAutoObservable, runInAction } from "mobx";
import { SelectValue } from "react-tailwindcss-select/dist/components/type";
import { placeDetailsToString } from "utils";
import { BuildingStore } from "./Building/BuildingStore";
import { b6ImporterStore } from "store/B6ImporterStore";

export type OutputFileType = "EXCEL" | "PDF" | "TXT" | "NONE";
export interface DGNBFormData {
  buildingId: string;
  outputType: OutputFileType;
  dgnbPassport: DGNBPassportIN;
}

export enum DGNBSteps {
  BuildingInformation,
  PollutantReport,
  EnergyConsumption,
  BuildingFlexibility,
  Success,
}

export class DGNBStore {
  activeStep = 0;
  buildingId = "";
  downloadSuccess = false;
  hasBuildingFieldsChanged = false;

  outputType: OutputFileType = "TXT";
  specialFeatures: SelectValue = null;

  dgnbPassport: DGNBPassportIN = this.initDgnbPass();

  formData: DGNBFormData = this.initFormData();

  steps: string[] = [
    "Building information",
    "Pollutant report",
    "Energy",
    "Building Flexibility",
  ];

  constructor() {
    makeAutoObservable(this);
    this.dgnbPassport = this.initDgnbPass();
    this.formData = this.initFormData();
    this.specialFeatures = null;
  }

  initFormData() {
    return {
      buildingId: this.buildingId,
      outputType: this.outputType,
      dgnbPassport: this.dgnbPassport,
    };
  }

  initDgnbPass() {
    return {
      address: "",
      date_of_construction: "",
      permit_of_construction: "",
      type_of_construction: "",
      type_of_operation: "",
      state_of_building: "",
      building_category: "",
      gross_floor_area: "",
      net_room_area: "",
      utility_unit: "",
      date_of_issue: "",
      energy_consumption: "",
      operation_result: "",
    };
  }

  setDGNBPassport(response: DGNBPassportIN) {
    runInAction(() => {
      this.dgnbPassport = response;
    });
  }

  resetData() {
    this.activeStep = 0;
    this.downloadSuccess = false;
  }

  setDGNBPassFieldAndValue(
    field: keyof DGNBPassportIN,
    value: string | boolean | undefined
  ) {
    runInAction(() => {
      this.dgnbPassport = {
        ...this.dgnbPassport,
        [field]: value,
      };
    });
  }

  handleOnDropdownChange(
    field: keyof DGNBPassportIN,
    items: {
      id: string;
      name: string;
      name_de?: string;
    }[],
    value: string | undefined
  ) {
    let selectedValue = undefined;
    if (value) {
      selectedValue = items.find((ct) => ct.id === value)?.name;
    }
    this.setDGNBPassFieldAndValue(field, selectedValue);
  }

  setSpecialFeature(value: SelectValue) {
    this.specialFeatures = value;
  }

  setActiveStep(step: number) {
    this.activeStep = step;
  }

  get validateFields() {
    if (
      !this.dgnbPassport.net_room_area ||
      this.dgnbPassport.net_room_area === "0"
    )
      return false;

    return true;
  }

  setFormData(data: Partial<DGNBFormData>) {
    this.formData = { ...this.formData, ...data };
  }

  get isFirstStep() {
    return this.activeStep === 0;
  }

  setBuildingId(buildingId: string) {
    this.buildingId = buildingId;
  }
  setOutputType(type: OutputFileType) {
    this.outputType = type;
  }

  async sendDGNBRequestToServer() {
    if (this.buildingId === null || this.buildingId === "") {
      throw new Error("Building Id should not be null or empty!!");
    }
    this.setFormData({
      buildingId: this.buildingId,
      outputType: this.outputType,
      dgnbPassport: this.dgnbPassport,
    });
    return await handleDgnbPassportCreate(this.formData).then(async () => {
      await BuildingStore.getInstance().getAllOrganizationsBuildings();
    });
  }

  setDownloadSuccess(value: boolean) {
    this.downloadSuccess = value;
  }

  prefillB6ValuesFromReport(report: ReportOUT | null | undefined) {
    let gwp_b6 = "";
    let penrt_b6 = "";

    if (report) {
      const entries = report.multiplied_values || [];
      const totals = entries.find((entry) => entry["yearly"] == "Summe B6");

      if (totals) {
        // we must convert it to a string otherwise the endpoint will complain
        gwp_b6 = String(Number(totals["gwp"]).toFixed(2));
        penrt_b6 = String(Number(totals["pe_penrt"]).toFixed(2));
      }
    }
    this.setDGNBPassFieldAndValue("gwp_b6", gwp_b6);
    this.setDGNBPassFieldAndValue("penrt_b6", penrt_b6);
  }

  prefilDGNBFromBuildingFields(
    formData: AddEditBuildingForm | undefined,
    buildingTypes: BuildingTypeOUT[]
  ) {
    let address = formData?.string_address;

    if (!address) {
      address = placeDetailsToString({
        ...formData?.address,
        postal_code: formData?.postal_code ?? "",
        region: formData?.region ?? "",
      } as PlaceDetailsOUT);
    }

    this.setDGNBPassFieldAndValue("address", `${address}`);

    if (formData?.construction_year !== null) {
      this.setDGNBPassFieldAndValue(
        "date_of_construction",
        `${formData?.construction_year}`
      );
    } else {
      this.setDGNBPassFieldAndValue("date_of_construction", "");
    }

    // get value against building category id
    const buildingCategoryDe = buildingTypes.find(
      (item) =>
        item.name_de === formData?.building_type?.name_de ||
        item.name === formData?.building_type?.name
    )?.name_de;
    if (buildingCategoryDe) {
      this.setDGNBPassFieldAndValue("building_category", buildingCategoryDe);
    }

    this.setDGNBPassFieldAndValue(
      "gross_floor_area",
      `${formData?.gross_floor_area}`
    );
    this.setDGNBPassFieldAndValue(
      "net_room_area",
      `${formData?.net_floor_area}`
    );

    // get value against upcoming_operation id
    const operationValueDe = UPCOMING_OPERATION_LIST.find(
      (item) => item.id === formData?.upcoming_operation
    )?.name_de;

    if (operationValueDe) {
      this.setDGNBPassFieldAndValue("type_of_operation", operationValueDe);
    }
  }

  async fetchLatestDgnbValues() {
    if (this.buildingId === null || this.buildingId === "") {
      throw new Error("Building Id should not be null or empty!!");
    }

    const latest = await handleDgnbPassportGetLatest(this.buildingId);
    await b6ImporterStore.fetchReportData();

    if (latest) {
      runInAction(() => {
        this.dgnbPassport = latest;
        this.prefillB6ValuesFromReport(b6ImporterStore.fileReport);
      });
    }
  }

  // Singleton instance of DGNB Store
  static instance: DGNBStore;

  static getInstance(): DGNBStore {
    if (!DGNBStore.instance) {
      DGNBStore.instance = new DGNBStore();
    }
    return DGNBStore.instance;
  }
}

import { EPDConformityOut } from "api-client";
import DropdownCheckbox from "components/DropdownCheckbox";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { searchStore } from "store/Mapping/SearchStore";

export default observer(function ConformityFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: EPDConformityOut[];
  setSelected: React.Dispatch<React.SetStateAction<EPDConformityOut[]>>;
  removingItem?: EPDConformityOut;
  handleRemoveItem?: () => void;
}) {
  const { t } = useTranslation();

  const onChange = (value: EPDConformityOut[]) => setSelected(value);

  React.useEffect(() => {
    (async () => {
      await dynamicMEStore.fetchConformities();
      searchStore.setFilterOptions({
        ...searchStore.filtersOptions,
        conformity: dynamicMEStore.conformityOptions.filter(
          (item) => item.name !== ""
        ),
      });
    })();
  }, []);

  if (!searchStore.filtersOptions.conformity) return <></>;
  return (
    <DropdownCheckbox<EPDConformityOut>
      displayKey={"name"}
      required={true}
      handleSelect={onChange}
      labelName={t("mapping.conformity")}
      name="conformity"
      items={searchStore.filtersOptions.conformity}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px]"
      checkedItems={selected}
      optionsClassName="z-40 !max-w-fit"
      noMargin
    />
  );
});

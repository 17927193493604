import React from "react";
import clsx from "clsx";
import { Column } from "@tanstack/react-table";
import useTableMenus from "./useTableMenus";

export interface MenuItem {
  label: string;
  icon?: React.ReactNode;
  onClick: (column?: Column<unknown, unknown>) => void;
  hidden?: boolean;
}

export interface ContextMenuOption {
  title?: string;
  options: MenuItem[];
}

const HeaderMenu = ({
  column,
  onClose,
}: {
  column?: Column<unknown, unknown>;
  onClose?: () => void;
}) => {
  const { propertyMenu } = useTableMenus(column);

  return (
    <ul className="list-none">
      {propertyMenu?.map(
        (item: ContextMenuOption, index: React.Key | null | undefined) => (
          <div key={`0_${index}`}>
            <div className="bg-gray-50 font-medium text-gray-500 px-4 py-1 border-b first-of-type:border-t-0 border-t text-left">
              {item.title}
            </div>
            {item.options.map((rec, index) => (
              <li
                key={index}
                className={clsx(
                  "cursor-pointer px-4 py-2 flex items-center font-medium text-sm text-gray-700",
                  "hover:bg-indigo-50 hover:text-indigo-700",
                  { hidden: rec.hidden }
                )}
                onClick={() => {
                  rec.onClick(column);
                  onClose?.();
                }}
              >
                {rec?.icon ? <span className="mr-1.5">{rec.icon}</span> : null}
                {rec.label}
              </li>
            ))}
          </div>
        )
      )}
    </ul>
  );
};

export default HeaderMenu;

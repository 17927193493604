import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef, Row } from "@tanstack/react-table";
import { IconSearch } from "@tabler/icons-react";
import useSelectColumn from "components/Table/useSelectColumn";
import { searchStore } from "store/Mapping/SearchStore";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { DynamicColumnOUT } from "api-client";
import { GroupingRemoveIcon, MappingStatus } from "../utils";
import ColumnSelectorBrowser from "../ColumnSelectorBrowser";
import Tooltip from "components/Tooltip";
import clsx from "clsx";
import { OBJECT_COUNT_ID } from "../const";
import useMappingEditor from "../useMappingEditor";
import useKeyPressed from "./useKeyPressed";

interface Info {
  row: { original: { [key: string]: string | number } };
}

export const COLUMNS_GROUPS = {
  infoColumns: "infoColumns",
  groupingColumns: "groupingColumns",
};

const useTableColumns = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { clearGrouping } = useMappingEditor();
  const informationalColumnsKeys = dynamicMEStore.getcurrentGroupKeys(
    COLUMNS_GROUPS.infoColumns
  );
  const keyboard = useKeyPressed();

  const headerCell = (group_name: string, class_names?: string) => (
    <div
      className={clsx(
        "px-2 py-1 flex justify-between w-full text-gray-500 items-center",
        "whitespace-nowrap text-xs",
        class_names
      )}
    >
      <span>{t(`mappingEditor.${group_name}`)}</span>
      <div className="flex items-center">
        <ColumnSelectorBrowser groupName={group_name} />
        {group_name === COLUMNS_GROUPS.groupingColumns && (
          <GroupingRemoveIcon
            size="16"
            stroke="3"
            classNames="ml-2 cursor-pointer"
            onClick={clearGrouping}
          />
        )}
      </div>
    </div>
  );

  function roundToThreeDecimals(input: string | number | null | undefined) {
    if (input === null || input === undefined) return "-";
    const num = Number(input);
    const roundedValue = num.toFixed(3);
    const isRoundedToZero = parseFloat(roundedValue) === 0 && num !== 0;
    return `${isRoundedToZero ? "~" : ""}${roundedValue}`.replace(".", ",");
  }

  const numericCell = (value: string | number | null) => {
    const rounded = roundToThreeDecimals(value);
    return (
      <Tooltip
        content={value}
        className={clsx(
          "text-right",
          dynamicMEStore.hasGrouping() ? "!cursor-auto" : "cursor-pointer"
        )}
        noFlex
      >
        {rounded ?? "-"}
      </Tooltip>
    );
  };

  const textCell = (
    value: string | number | null,
    count?: string | number | undefined
  ) => {
    return (
      <div className="text-left">
        {Number(count) > 1 ? (
          <div className="flex justify-between text-gray-500">
            <span className="text-sm font-medium">
              {t("mappingEditor.different")}
            </span>
            <Tooltip
              content={t("mappingEditor.differentValuesTooltip")}
            ></Tooltip>
          </div>
        ) : (
          value ?? "-"
        )}
      </div>
    );
  };

  const columnCell = (isGroupingColumn: boolean, col: DynamicColumnOUT) => ({
    accessorFn: (row: { [key: string]: string }) => row[col.key_name],
    id: col.key_name,
    label: isEng ? col.friendly_name : col.friendly_name_de,
    header: () => (
      <div className="flex flex-col">
        <span className="truncate">
          {isEng ? col.friendly_name : col.friendly_name_de}
        </span>
        {dynamicMEStore.hasGrouping() && !isGroupingColumn ? (
          <span className="text-xs text-gray-500 font-medium">
            {col.type === "NUMERIC"
              ? t("mappingEditor.sum")
              : t("mappingEditor.uniqueness")}
          </span>
        ) : null}
      </div>
    ),
    cell: (info: Info) => {
      const value = info.row.original[col.key_name];
      const count = info.row.original[`${col.key_name}__count`];
      return col.type === "NUMERIC"
        ? numericCell(value)
        : textCell(value, count);
    },
    enableColumnFilter: false,
    enableSorting: false,
    enableMenu: true,
    cellClassName: clsx({
      "bg-gray-50 !border-r-gray-100": isGroupingColumn,
    }),
    headerClassName: clsx({
      "bg-gray-100": isGroupingColumn,
      "border-r-0": col.key_name === dynamicMEStore.columnsOrder.slice(-1)[0],
    }),
  });

  const informationalColumns = () => {
    return {
      id: COLUMNS_GROUPS.infoColumns,
      header: () => headerCell(COLUMNS_GROUPS.infoColumns),
      columns: dynamicMEStore.dynamicColumns
        .filter((col) => informationalColumnsKeys.includes(col.key_name))
        .map((col) => columnCell(false, col)),
      headerClassName: "border-r-0",
    };
  };

  const groupingColumns = () => {
    const visibleKeys = dynamicMEStore.validGroupByRules.map(
      (item) => item.column_key
    );

    const columns = dynamicMEStore.dynamicColumns
      .filter((col) => visibleKeys.includes(col.key_name))
      .map((col) => columnCell(true, col));

    return {
      id: COLUMNS_GROUPS.groupingColumns,
      header: () => headerCell(COLUMNS_GROUPS.groupingColumns, "text-white"),
      columns: [objectCountCell(), ...columns],
      headerClassName: "bg-gray-500 text-white",
    };
  };

  const objectCountCell = () => ({
    id: OBJECT_COUNT_ID,
    header: () => (
      <span className="truncate">{t("mappingEditor.numberOfObjects")}</span>
    ),
    cell: (info: Info) => textCell(info.row.original[OBJECT_COUNT_ID]),
    enableColumnFilter: false,
    cellClassName: "bg-gray-50 !border-r-gray-100",
    headerClassName: "bg-gray-100",
    size: 70,
    maxWidth: 70,
    minWidth: 70,
    maxSize: 70,
  });

  function onClickMappingCell(e: React.MouseEvent, row: Row<unknown>) {
    e.stopPropagation();
    dynamicMEStore.setCurrentRow(row);
    !dynamicMEStore.openProductDetailsPopup.open &&
      searchStore.setOpenProductSearch(true);
  }

  const onSelectAllClicked = (checked: boolean) => {
    dynamicMEStore.setSelectAllChecked(checked);
  };

  const onRowChecked = (row_index: number) => {
    if (dynamicMEStore.keyPressed != "Shift") {
      dynamicMEStore.setSelectedStartIndex(row_index);
    } else if (
      dynamicMEStore.selectedStartIndex != null &&
      dynamicMEStore.keyPressed === "Shift"
    ) {
      keyboard.onPressedShift(row_index);
    }
  };

  const selectCell = () => {
    return {
      id: "selectGroup",
      header: () => <div className="w-[14px]"></div>,
      disableResizing: true,
      size: 32,
      maxWidth: 32,
      maxSize: 32,
      columns: [useSelectColumn(onSelectAllClicked, onRowChecked)],
      isSticky: true,
      enableSorting: false,
    };
  };

  const mappingCell = () => ({
    id: "mappingGroup",
    header: () => <></>,
    columns: [
      {
        id: "mappingCell",
        header: () => t("mapping.objectMapping"),
        cell: (info: { row: Row<unknown> }) => (
          <div
            className="flex justify-between items-center cursor-pointer group/mapp"
            test-id={`manualmapping_row_${info.row.index}`}
          >
            <MappingStatus row={info.row} />
            <IconSearch className="w-5 transition stroke-gray-500 group-hover/mapp:stroke-gray-500" />
          </div>
        ),
        onClick: (e: React.MouseEvent, row: Row<unknown>) =>
          onClickMappingCell(e, row),
        enableColumnFilter: false,
        size: 340,
        minWidth: 340,
        maxSize: 340,
        maxWidth: 340,
        disableResizing: true,
        isSticky: true,
        stickyClass:
          "right-0 left-auto shadow-[-1px_0_4px_-2px_rgba(0,0,0,0.25)] border-l",
      },
    ],
    isSticky: true,
    size: 340,
    minWidth: 340,
    maxSize: 340,
    maxWidth: 340,
    stickyClass:
      "right-0 left-auto shadow-[-1px_0_4px_-2px_rgba(0,0,0,0.25)] border-l",
  });

  return useMemo<ColumnDef<{ [key: string]: string }>[]>(
    () => [
      selectCell(),
      ...(dynamicMEStore.hasGrouping() ? [groupingColumns()] : []),
      ...(informationalColumnsKeys.length ? [informationalColumns()] : []),
      mappingCell(),
    ],
    [
      dynamicMEStore.dynamicColumns.length,
      dynamicMEStore.visibleColumnProperties.length,
      dynamicMEStore.validGroupByRules.length,
    ]
  );
};

export default useTableColumns;

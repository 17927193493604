import React from "react";
import {
  getModuleStringB4,
  getModuleStringTotal,
  numberCustomFormat,
  unitPower,
} from "utils";
import { CustomCollapse } from "./CustomCollapse";
import ListSectionHeader from "./ListSectionHeader";
import { useTranslation } from "react-i18next";
import {
  LCAIndicatorValue,
  LCAIndicatorValueModule,
  ReportTileReportMass,
} from "api-client";
import { observer } from "mobx-react-lite";
import Alert from "components/Alert";
import { detailViewV2Store } from "store/Mapping/DetailViewStore";
import Tooltip from "components/Tooltip";
import { buildingStore } from "store/Building/BuildingStore";

interface CollapseStates {
  [key: string]: boolean;
}

interface Props {
  lcaIndicatorValues: LCAIndicatorValue[] | undefined;
  mass: ReportTileReportMass | null | undefined;
  onReload?: () => void;
}

const LifeCycleListItem = ({
  lcaIndicatorValues: items,
  mass,
  onReload,
}: Props) => {
  const { t } = useTranslation();

  const [collapseStates, setCollapseStates] = React.useState(() => {
    const initialStates: CollapseStates = {};
    items?.forEach((item) => {
      initialStates[item.indicator_name] = item.modules?.length === 0;
    });
    return initialStates;
  });

  const toggleCollapse = (itemId: string) => {
    setCollapseStates((prevStates) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  const expandAll = () => {
    setCollapseStates(() => {
      const expandedStates: CollapseStates = {};
      items?.forEach((item) => {
        expandedStates[item.indicator_name] = true;
      });
      return expandedStates;
    });
  };

  const collapseAll = () => {
    setCollapseStates(() => {
      const collapsedStates: CollapseStates = {};
      items?.forEach((item) => {
        collapsedStates[item.indicator_name] = item.modules?.length === 0;
      });
      return collapsedStates;
    });
  };

  const itemElement = (
    key: string,
    value: number | null | undefined,
    unit: string | null | undefined,
    isSingleItem: boolean,
    tooltip?: string
  ) => {
    return (
      <li
        key={key}
        className={`${
          isSingleItem
            ? "border-b"
            : "last:border-b-0 border-b border-b-gray-100"
        } text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
      >
        {key}
        <div className="text-sm font-normal flex">
          {numberCustomFormat(value ?? 0)} {unitPower(unit ?? "")}
          {tooltip ? <Tooltip content={tooltip}></Tooltip> : null}
        </div>
      </li>
    );
  };

  function checkIsValueValid(value: number | null | undefined) {
    if (value === null || value === undefined) return false;
    return true;
  }

  function checkNullValues() {
    if (!items) return false;

    for (const item of items) {
      if (item.value !== null && item.value !== undefined) {
        return false;
      }

      if (item.modules) {
        for (const module of item.modules) {
          if (module.value !== null && module.value !== undefined) {
            return false;
          }
        }
      }
    }
    return true;
  }

  const isObjectMapped = detailViewV2Store.isObjectMapped;

  function tooltipTextB4(m: LCAIndicatorValueModule): string | undefined {
    return m.module_name === "B4"
      ? t("detailView.reportsTab.modulesAlert", {
          moduleString: t(
            `commons.${getModuleStringB4(
              buildingStore.getBuildingCertificationName()
            )}`
          ),
        })
      : undefined;
  }

  function tooltipTextTotal() {
    return t("detailView.reportsTab.modulesAlert", {
      moduleString: t(
        `commons.${getModuleStringTotal(
          buildingStore.getBuildingCertificationName()
        )}`
      ),
    });
  }

  return (
    <>
      <ListSectionHeader
        showButtons={!checkNullValues() && !!isObjectMapped}
        onExpandAll={expandAll}
        onCollapseAll={collapseAll}
        title={t("detailView.reportsTab.lifeCycleAssesment")}
      />
      {!isObjectMapped || (!mass?.value && checkNullValues()) ? (
        <Alert
          type="info"
          description={
            !isObjectMapped
              ? t("detailView.reportsTab.noLcaAlert")
              : t("detailView.reportsTab.noLcaDataAlert")
          }
          actionButton={t("detailView.reportsTab.reload")}
          actionButtonOnClick={onReload}
        />
      ) : (
        <ul className={`border border-gray-300 rounded-md p-0 flex-col`}>
          {mass?.value &&
            itemElement(
              t("detailView.reportsTab.mass"),
              mass.value,
              mass.unit,
              true
            )}
          {items?.map(
            (item, index) =>
              checkIsValueValid(item.value) && (
                <CustomCollapse
                  isOpen={collapseStates[item.indicator_name]}
                  toggleCollapse={() => toggleCollapse(item.indicator_name)}
                  key={item.indicator_name}
                  title={item.indicator_name}
                  value={numberCustomFormat(item?.value ?? 0)}
                  unit={item.unit}
                  tooltip={tooltipTextTotal()}
                  isLastIndex={index === items.length - 1}
                >
                  {item.modules?.map(
                    (m) =>
                      checkIsValueValid(m.value) &&
                      itemElement(
                        m.module_name,
                        m.value,
                        m.unit,
                        false,
                        tooltipTextB4(m)
                      )
                  )}
                </CustomCollapse>
              )
          )}
        </ul>
      )}
    </>
  );
};

export default observer(LifeCycleListItem);

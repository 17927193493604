import { Row } from "@tanstack/react-table";
import { SingleItem } from "api-client";
import React from "react";
import { useTranslation } from "react-i18next";
import DMEMappingChip from "./DMEMappingChip";
import useMappingEditor from "./useMappingEditor";

export const MappingStatus = ({
  row,
  i,
}: {
  row: Row<unknown>;
  i?: string;
}) => {
  const { t } = useTranslation();
  const object = row?.original as SingleItem;
  const [loading, setLoading] = React.useState(false);
  const { mappingToObjects } = useMappingEditor();

  const handleUnmatch = React.useCallback(async () => {
    setLoading(true);
    await mappingToObjects("undo_match", null, object);
    setLoading(false);
  }, [row]);

  if (object.product_match.multiple_matches)
    return (
      <span className="text-gray-500">
        {t("mappingEditor.multipleMappings")}
      </span>
    );

  if (object.product_match?.id) {
    return (
      <DMEMappingChip
        product={object.product_match}
        unmatch={handleUnmatch}
        loading={loading}
      />
    );
  }

  return (
    <div key={i} className="text-xs text-gray-400 w-max">
      {t("mapping.searchAndMap")}
    </div>
  );
};

export function GroupingRemoveIcon(props: {
  size?: string;
  stroke?: string;
  classNames?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "20"}
      height={props.size ?? "20"}
      viewBox="0 0 24 24"
      fill="none"
      className={props.classNames}
      onClick={props.onClick}
    >
      <path
        d="M21 17V13C21 11.8954 20.1046 11 19 11M19 11V9C19 7.89543 18.1046 7 17 7M19 11L15 11M5 11C3.89543 11 3 11.8954 3 13V19C3 20.1046 3.89543 21 5 21H19C19.4393 21 19.8456 20.8583 20.1756 20.6182M5 11V9C5 7.89543 5.89543 7 7 7M5 11H11M17 7V5C17 3.89543 16.1046 3 15 3H9C8.40265 3 7.86647 3.26188 7.5 3.67709M17 7L11 7M3 3L21 21"
        stroke="currentColor"
        strokeWidth={props.stroke ?? "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GroupingAddIcon(props: {
  size?: string;
  stroke?: string;
  classNames?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "20"}
      height={props.size ?? "20"}
      viewBox="0 0 24 24"
      fill="none"
      className={props.classNames}
    >
      <path
        d="M5 11H19M5 11C3.89543 11 3 11.8954 3 13V19C3 20.1046 3.89543 21 5 21L13 21M5 11V9C5 7.89543 5.89543 7 7 7M19 11C20.1046 11 21 11.8954 21 13M19 11V9C19 7.89543 18.1046 7 17 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17M16 19H22M19 16V22"
        stroke="currentColor"
        strokeWidth={props.stroke ?? "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import React, { useState } from "react";
import SearchField from "./SearchField";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import { IconExternalLink, IconTrash, IconX } from "@tabler/icons-react";
import ProductEditorEPDSearch from "../../EPDSearch";
import { observer } from "mobx-react-lite";
import {
  buildingProductStore,
  EPDRowProps,
} from "store/Mapping/BuildingProductStore";
import Badge from "components/Badge";
import MaterialSearch from "../../MaterialSearch";
import clsx from "clsx";
import Tooltip from "components/Tooltip";
import { getCurrentReferenceValue } from "features/MappingTools/utils";

export default observer(function EPDRow(props: {
  showLabels?: boolean;
  row: EPDRowProps;
  datasetIndex: number;
  rowIndex: number;
  className: string;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const labelStyle =
    "text-xs sm:text-sm mb-3 flex items-center text-gray-700 h-6";
  const [openEPDSearch, setOpenEPDSearch] = useState(false);
  const [openMaterialSearch, setOpenMaterialSearch] = useState(false);
  const currentRow =
    buildingProductStore.data.groups?.[props.datasetIndex]?.epds?.[
      props.rowIndex
    ];

  const removeSelectedEPD = () => {
    buildingProductStore.onChangeDataset(
      "epd",
      undefined,
      props.datasetIndex,
      props.rowIndex
    );
    buildingProductStore.checkEpdValidationErrors();
    onChangeFactor("factor", undefined);
  };

  const removeSelectedMaterial = () => {
    buildingProductStore.onChangeDataset(
      "material",
      undefined,
      props.datasetIndex,
      props.rowIndex
    );
    buildingProductStore.checkMaterialValidationErrors();
  };

  const onChangeFactor = (
    name: string | undefined,
    value: string | undefined
  ) => {
    buildingProductStore.removeFactorErrors(props.datasetIndex, props.rowIndex);
    buildingProductStore.onChangeDataset(
      name as string,
      value,
      props.datasetIndex,
      props.rowIndex
    );
  };

  const itemReferenceUnit = getCurrentReferenceValue(
    currentRow?.epd?.reference_unit.toUpperCase()
  );

  return (
    <div className={clsx("flex w-full gap-4", props.className)}>
      <div className="w-60">
        {props.showLabels ? (
          <label className={labelStyle}>
            {`${t("productEditor.factor")}*`}
            <Tooltip
              content={t("productEditor.factorTooltip")}
              iconClassName="text-gray-500"
            />
          </label>
        ) : null}
        <div className="w-[150px]">
          {currentRow?.epd?.id ? (
            <TextInput
              unit={`${
                isEng
                  ? itemReferenceUnit?.name ?? "-"
                  : itemReferenceUnit?.name_de ?? "-"
              }/${buildingProductStore.getValueFromObject(
                "reference_unit",
                isEng
              )}`}
              noMargin
              min={0}
              placeholder="0.01"
              name="factor"
              handleChange={(value) => onChangeFactor("factor", value)}
              value={currentRow.factor ?? ""}
              type="number"
              hasError={
                buildingProductStore.hasFactorValidationError(
                  props.datasetIndex,
                  props.rowIndex
                ) || Number(currentRow.factor) === 0
              }
              className="rounded-md min-w-[150px]"
            />
          ) : null}
        </div>
      </div>
      <div className="w-full">
        {props.showLabels ? (
          <label className={labelStyle}>
            EPD*
            <Tooltip
              content={t("productEditor.epdTooltip")}
              iconClassName="text-gray-500"
            />
          </label>
        ) : null}
        {currentRow?.epd?.name_de ?? currentRow?.epd?.name_en ? (
          <Badge type="auto" className="mt-1">
            <>
              <a
                href={currentRow.epd?.url as string}
                target={"_blank"}
                rel="noreferrer"
              >
                <IconExternalLink
                  className="mr-1 text-indigo-700 cursor-pointer"
                  width={18}
                  stroke={3}
                />
              </a>
              <Tooltip
                truncate
                iconClassName="text-gray-500 max-w-[100px]"
                className="max-w-[160px]"
              >
                {currentRow.epd?.name_de ?? (currentRow.epd?.name_en as string)}
              </Tooltip>
              <IconX
                className="text-indigo-500 ml-1 cursor-pointer"
                width={18}
                onClick={removeSelectedEPD}
              />
            </>
          </Badge>
        ) : (
          <SearchField
            placeholder={t("productEditor.searchForAnEPD")}
            onClick={() => setOpenEPDSearch(true)}
            hasError={buildingProductStore.hasEpdValidationError(
              props.datasetIndex,
              props.rowIndex
            )}
          />
        )}
      </div>
      <div className="w-full">
        {props.showLabels ? (
          <label className={labelStyle}>
            Material*
            <Tooltip
              content={t("productEditor.materialTooltip")}
              iconClassName="text-gray-500"
            />
          </label>
        ) : null}
        {currentRow?.material ? (
          <Badge type="auto" className="mt-1">
            <>
              <Tooltip
                truncate
                iconClassName="text-gray-500"
                className="max-w-[150px]"
              >
                {currentRow?.material?.name}
              </Tooltip>
              <IconX
                className="text-indigo-500 ml-1 cursor-pointer"
                width={18}
                onClick={removeSelectedMaterial}
              />
            </>
          </Badge>
        ) : (
          <SearchField
            placeholder={t("productEditor.searchForAnMaterial")}
            onClick={() => setOpenMaterialSearch(true)}
            hasError={buildingProductStore.hasMaterialValidationError(
              props.datasetIndex,
              props.rowIndex
            )}
          />
        )}
      </div>
      <div>
        {props.showLabels ? (
          <label className={`${labelStyle} h-5 mb-5`}></label>
        ) : null}
        <div
          className={clsx(
            " w-7 h-7",
            props.showLabels
              ? ""
              : "border border-red-300 rounded-[4px] hover:bg-red-200 flex justify-center items-center cursor-pointer mt-1"
          )}
        >
          {!props.showLabels ? (
            <IconTrash
              className="text-red-700"
              width={18}
              onClick={() =>
                buildingProductStore.removeEpdRow(
                  props.rowIndex,
                  props.datasetIndex
                )
              }
            />
          ) : null}
        </div>
      </div>
      {openEPDSearch ? (
        <ProductEditorEPDSearch
          open={openEPDSearch}
          close={() => setOpenEPDSearch(false)}
          datasetIndex={props.datasetIndex}
          rowIndex={props.rowIndex}
        />
      ) : null}
      {openMaterialSearch ? (
        <MaterialSearch
          open={openMaterialSearch}
          close={() => setOpenMaterialSearch(false)}
          datasetIndex={props.datasetIndex}
          rowIndex={props.rowIndex}
        />
      ) : null}
    </div>
  );
});

import React from "react";
import DropdownBrowser from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import LinearLoading from "components/LinearLoading";
import { CollectionIcon } from "@heroicons/react/outline";
import { GroupingAddIcon, GroupingRemoveIcon } from "../../utils";
import useMappingEditor from "../../useMappingEditor";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import GroupDraggbleItem from "./GroupDraggbleItem";

function GroupByMenu({
  cursorPosition,
  items,
  onSelectColumn,
}: {
  cursorPosition: { x: number; y: number };
  items: PropertyBrowserTreeNode[];
  onSelectColumn: (selectedItems: PropertyBrowserTreeNode[]) => void;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const { clearGrouping } = useMappingEditor();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const removeCurrentRow = (id: string) => {
    dynamicMEStore.removeGroupingRule(id);
  };

  const addColumn = async (selectedItems: PropertyBrowserTreeNode[]) => {
    onSelectColumn(selectedItems);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = dynamicMEStore.selectedGroupByItems.findIndex(
        (item) => item.id === active.id
      );
      const newIndex = dynamicMEStore.selectedGroupByItems.findIndex(
        (item) => item.id === over?.id
      );

      const reorderedItems = arrayMove(
        dynamicMEStore.selectedGroupByItems,
        oldIndex,
        newIndex
      );

      dynamicMEStore.setSelectedGroupByItems(reorderedItems);
      reorderVisibleColumnsOnDrag(reorderedItems);
    }
  };

  function reorderVisibleColumnsOnDrag(
    updatedItems: PropertyBrowserTreeNode[]
  ) {
    const reorderedItems = Array.from(dynamicMEStore.visibleColumnProperties);
    reorderedItems.splice(0, updatedItems.length, ...updatedItems);
    dynamicMEStore.setVisibleColumnProperties(reorderedItems);
  }

  const rows = dynamicMEStore.selectedGroupByItems;

  function footerTile() {
    return (
      <div className="border-t border-gray-200 text-gray-700 transition hover:text-gray-900">
        <DropdownBrowser<PropertyBrowserTreeNode>
          displayKey={isEng ? "name" : "name_de"}
          detailKey={isEng ? "classification" : "classification_de"}
          handleSelect={addColumn}
          checkedItems={dynamicMEStore.selectedGroupByItems}
          items={items}
          placeholder={t("mappingEditor.findProperty")}
          optionsClassName="!max-w-fit min-w-[246px] !right-0"
          fixedPos
          titleOrIcon={
            <div className="flex w-full px-4 py-2 items-center cursor-pointer hover:bg-indigo-50 hover:text-indigo-700 whitespace-nowrap">
              <GroupingAddIcon />
              <span className="ml-2">{t("mappingEditor.addGrouping")}</span>
            </div>
          }
        />
        <div
          className={clsx(
            "flex px-4 py-2 items-center cursor-pointer",
            rows.length
              ? "text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group"
              : "pointer-events-none cursor-not-allowed text-gray-300"
          )}
          onClick={clearGrouping}
        >
          <GroupingRemoveIcon />
          <span className="ml-2">{t("mappingEditor.removeGrouping")}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center px-4 py-1 rounded-t-md border-b border-gray-300 bg-gray-500 text-xs text-white">
        <CollectionIcon className="mr-2" width={19} strokeWidth={2.5} />
        {`${t("mappingEditor.grouped")} ${t("mappingEditor.by")}`}
      </div>

      {rows.length ? (
        <div
          className={clsx("pb-1 max-h-[325px] relative", {
            "overflow-y-auto": rows.length > 6,
          })}
        >
          <div className="h-1">
            {dynamicMEStore.dataFetchLoading && <LinearLoading type="auto" />}
          </div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={rows.map((row) => row.id)}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {rows.map((row) => (
                  <GroupDraggbleItem
                    key={row.id}
                    id={row.id}
                    name={isEng ? row.name : row.name_de}
                    onRemove={() => removeCurrentRow(row.id)}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
          <div className="h-[1px]" ref={scrollAreaRef}></div>
        </div>
      ) : (
        <div className="px-4 py-3 text-gray-500 text-xs">
          {t("mappingEditor.noGroupingProperty")}
        </div>
      )}
      {footerTile()}
    </div>
  );
}

export default observer(GroupByMenu);

import { Row, Table } from "@tanstack/react-table";
import React, { HTMLProps } from "react";

const useSelectColumn = (
  onSelectAllClicked?: ((arg0: boolean) => void) | undefined,
  onSelectRow?: ((row_index: number) => void) | undefined
) => {
  function IndeterminateCheckbox({
    indeterminate,
    className = "",
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + "cursor-pointer"}
        {...rest}
      />
    );
  }

  function onRowClicked(e: { stopPropagation: () => void }, row: Row<unknown>) {
    e.stopPropagation();
    row.toggleSelected();

    if (!row.getIsSelected()) {
      onSelectRow?.(row.index);
    } else {
      onSelectAllClicked?.(false);
    }
  }

  const selectColumn = {
    id: "select",
    header: ({ table }: { table: Table<unknown> }) => (
      <div
        className="flex justify-center w-full h-full"
        onClick={(e) => {
          e.stopPropagation();
          const isAnyRowSelected =
            table.getIsSomeRowsSelected() || table.getIsAllRowsSelected();
          if (isAnyRowSelected) {
            table.resetRowSelection();
            onSelectAllClicked?.(false);
          } else {
            table.getToggleAllRowsSelectedHandler()(e);
            onSelectAllClicked?.(!table.getIsAllRowsSelected());
          }
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
          }}
        />
      </div>
    ),
    cell: ({ row }: { row: Row<unknown> }) => (
      <div
        className="flex py-2.5 justify-center w-full min-h-full"
        onClick={(e) => onRowClicked(e, row)}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    disableResizing: true,
    size: 33,
    maxWidth: 33,
    minWidth: 33,
    isSticky: true,
    stickyClass: "bg-white",
  };

  return selectColumn;
};

export default useSelectColumn;

import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "@tabler/icons-react";
import { buildingProductStore } from "store/Mapping/BuildingProductStore";
import { observer } from "mobx-react-lite";

export default observer(function ProductEditorFooter() {
  const { t } = useTranslation();

  const goNextPage = () =>
    buildingProductStore.setActiveStep(buildingProductStore.activeStep + 1);

  const goPreviousPage = () =>
    buildingProductStore.setActiveStep(buildingProductStore.activeStep - 1);

  const showHint = () => {
    if (buildingProductStore.activeStep === 2) {
      return t("productEditor.footerHint2");
    } else {
      return t("productEditor.footerHint1");
    }
  };

  return (
    <div className="sm:flex bottom-0 absolute w-full bg-white px-4 py-2 items-center justify-between border-t border-gray-300">
      {buildingProductStore.activeStep !== 0 ? (
        <Button
          width="fit-content"
          className="block"
          type="gray"
          onClick={goPreviousPage}
        >
          {t("commons.back")}
        </Button>
      ) : null}
      <div className="flex items-center w-full justify-center pl-4">
        <IconInfoCircle
          width={25}
          height={25}
          className="text-indigo-600 bg-indigo-100 p-1 rounded-full font-semibold"
        />
        <div className="text-sm font-normal text-indigo-600 ml-2">
          {showHint()}
        </div>
      </div>
      {buildingProductStore.activeStep !== 2 ? (
        <Button
          width="fit-content"
          className="block"
          type="primary"
          onClick={goNextPage}
          disable={
            !buildingProductStore.checkStepValidity(
              buildingProductStore.activeStep
            )
          }
        >
          {t("commons.next")}
        </Button>
      ) : null}
    </div>
  );
});

import React from "react";
import { DGNBStore } from "store/DGNBStore";
import { observer } from "mobx-react-lite";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import { FormHelperLabel, StepHeading } from "./utils";

const EnergyConsumption = () => {
  const store = DGNBStore.getInstance();
  const { dgnbPassport } = store;
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col px-6">
      <StepHeading text={t("DGNB.co2_emission_heading")} />

      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            unit={"kg CO2e/m²NRF*a"}
            labelName={t("DGNB.gwp_b6__label")}
            placeholder={t("DGNB.gwp_b6__placeholder")}
            type="number"
            name="gwp_b6"
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue("gwp_b6", value)
            }
            value={Number(dgnbPassport.gwp_b6).toFixed(2)}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.gwp_b6__helper")} />
        </div>

        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            unit={"kWhne/m²NRF*a"}
            labelName={t("DGNB.penrt_b6__label")}
            placeholder={t("DGNB.penrt_b6__placeholder")}
            type="number"
            name="penrt_b6"
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue("penrt_b6", value)
            }
            value={Number(dgnbPassport.penrt_b6).toFixed(2)}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.penrt_b6__helper")} />
        </div>
      </div>
    </div>
  );
};

export default observer(EnergyConsumption);

import React from "react";
import DropdownBrowser from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import { IconEye, IconEyePlus } from "@tabler/icons-react";
import clsx from "clsx";
import { PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { observer } from "mobx-react-lite";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import ReactDOM from "react-dom";
import LinearLoading from "components/LinearLoading";
import { COLUMNS_GROUPS } from "../DMETableView/useTableColumns";
import { CollectionIcon } from "@heroicons/react/outline";
import { GroupingAddIcon, GroupingRemoveIcon } from "../utils";
import useMappingEditor from "../useMappingEditor";
import DraggbleItem from "./DraggbleItem";

function SelectorMenu(props: {
  close: () => void;
  onAddColumn: (value: PropertyBrowserTreeNode[]) => void;
  filteredItems: PropertyBrowserTreeNode[];
  selectedItems: PropertyBrowserTreeNode[];
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const { clearGrouping } = useMappingEditor();

  const isGroupingColumn =
    dynamicMEStore.openColumnSettings.groupName ===
    COLUMNS_GROUPS.groupingColumns;

  const onSelectColumn = async (value: PropertyBrowserTreeNode[]) => {
    props.onAddColumn(value);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeGrouping = () => {
    clearGrouping();
    props.close();
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const idxOffset = isGroupingColumn
      ? 0
      : dynamicMEStore.validGroupByRules.length;
    const sourceIndex =
      dynamicMEStore.visibleColumnProperties.findIndex(
        (item) => item.id === active.id
      ) - idxOffset;
    const destinationIndex =
      dynamicMEStore.visibleColumnProperties.findIndex(
        (item) => item.id === over.id
      ) - idxOffset;

    const reorderedItems = arrayMove(
      dynamicMEStore.visibleColumnProperties,
      sourceIndex + idxOffset,
      destinationIndex + idxOffset
    );

    dynamicMEStore.setVisibleColumnProperties(reorderedItems);
    if (isGroupingColumn) {
      reorderGroupByList(reorderedItems);
    }
  };

  function reorderGroupByList(updatedItems: PropertyBrowserTreeNode[]) {
    const groupingItems = updatedItems.splice(
      0,
      dynamicMEStore.selectedGroupByItems.length
    );
    dynamicMEStore.setSelectedGroupByItems(groupingItems);
  }

  const currentGroupKeys = dynamicMEStore.getcurrentGroupKeys(
    dynamicMEStore.openColumnSettings.groupName
  );

  const currentGroupRows = props.selectedItems.filter((item) =>
    currentGroupKeys.includes(item.column_key)
  );

  const sensors = useSensors(useSensor(PointerSensor));

  return ReactDOM.createPortal(
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(dynamicMEStore.openColumnSettings.positionX, 20),
        top: dynamicMEStore.openColumnSettings.positionY,
        zIndex: 20,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={clsx(
          "flex items-center px-4 py-1 border-b border-gray-300 text-xs rounded-t-md",
          isGroupingColumn
            ? "bg-gray-500 text-white"
            : "text-gray-500 bg-gray-50"
        )}
      >
        {isGroupingColumn ? (
          <>
            <CollectionIcon className="mr-2" width={19} strokeWidth={2.5} />
            {`${t("mappingEditor.grouped")} ${t("mappingEditor.by")}`}
          </>
        ) : (
          <>
            <IconEye size={19} className="mr-2" stroke={2.5} />
            {t("mappingEditor.displayedColumns")}
          </>
        )}
      </div>

      {currentGroupRows ? (
        <div
          className={clsx("pb-1 max-h-[325px] relative", {
            "overflow-y-auto": currentGroupRows.length > 6,
          })}
        >
          <div className="h-1">
            {dynamicMEStore.dataFetchLoading && <LinearLoading type="auto" />}
          </div>
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext
              items={currentGroupRows.map((row) => row.id)}
              strategy={verticalListSortingStrategy}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                {currentGroupRows.map((row) => (
                  <DraggbleItem
                    key={row.id}
                    row={row}
                    isGroupingColumn={isGroupingColumn}
                    selectedItemsLength={props.selectedItems.length}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
          <div className="h-[1px]" ref={scrollAreaRef}></div>
        </div>
      ) : null}
      <div className="border-t border-gray-200 text-gray-700 transition hover:text-gray-900">
        <DropdownBrowser<PropertyBrowserTreeNode>
          displayKey={isEng ? "name" : "name_de"}
          detailKey={isEng ? "classification" : "classification_de"}
          handleSelect={onSelectColumn}
          items={props.filteredItems}
          checkedItems={props.selectedItems}
          placeholder={t("mappingEditor.findProperty")}
          optionsClassName="!max-w-fit min-w-[246px] !right-0"
          fixedPos
          titleOrIcon={
            <div
              className={clsx(
                "flex w-full px-4 py-2 items-center cursor-pointer hover:bg-indigo-50",
                "hover:text-indigo-700 whitespace-nowrap rounded-b-md"
              )}
            >
              {isGroupingColumn ? (
                <>
                  <GroupingAddIcon classNames="mr-2" size="20" stroke="2.5" />
                  {t("mappingEditor.addGrouping")}
                </>
              ) : (
                <>
                  <IconEyePlus className="mr-2" size={20} stroke={2.5} />
                  <span>{t("mappingEditor.addProperty")}</span>
                </>
              )}
            </div>
          }
        />
        {isGroupingColumn ? (
          <div
            className={clsx(
              "flex px-4 py-2 items-center cursor-pointer",
              currentGroupRows.length
                ? "text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group"
                : "pointer-events-none cursor-not-allowed text-gray-300"
            )}
            onClick={removeGrouping}
          >
            <GroupingRemoveIcon stroke="2.5" />
            <span className="ml-2">{t("mappingEditor.removeGrouping")}</span>
          </div>
        ) : null}
      </div>
    </div>,
    document.body
  );
}

export default observer(SelectorMenu);

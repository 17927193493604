import React from "react";
import { observer } from "mobx-react-lite";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import clsx from "clsx";
import FilterMenu from "./FilterMenu";
import { ColumnSuggestionsOUT, PropertyBrowserTreeNode } from "api-client";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import DropdownBrowser from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { EMPTY_FILTER_RULE } from "../../const";

export interface FilterRule {
  property: PropertyBrowserTreeNode | undefined;
  operator: string;
  value: string;
  suggestedValues?: ColumnSuggestionsOUT[];
}

export default observer(function FilterByPropertyBrowser() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { ifc_id } = useParams();

  const openIconRef = React.useRef<HTMLDivElement>(null);
  const closeView = () => dynamicMEStore.setOpenFilter(false);

  React.useEffect(() => {
    document.addEventListener("click", closeView);
    return () => {
      document.removeEventListener("click", closeView);
    };
  }, []);

  const handleOnOpenMenu = () => {
    dynamicMEStore.setOpenFilter(!dynamicMEStore.openFilter);
  };

  const onChangeSuggestedValues = (
    values: ColumnSuggestionsOUT[],
    index: number
  ) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].suggestedValues = values;
    dynamicMEStore.updateFilterRules(rules);
  };

  const onChangeTerm = (value: string | number | undefined, index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].value = String(value);
    dynamicMEStore.updateFilterRules(rules);
  };

  function clearTerm(index: number) {
    onChangeSuggestedValues([], index);
    onChangeTerm("", index);
  }

  const addFirstRule = (value: PropertyBrowserTreeNode[]) => {
    dynamicMEStore.updateFilterRules([EMPTY_FILTER_RULE]);
    onChangeProperty(value, 0, true);
  };

  const onChangeProperty = async (
    value: PropertyBrowserTreeNode[],
    index: number,
    openMenu?: boolean
  ) => {
    clearTerm(index);
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].property = value[0];
    dynamicMEStore.updateFilterRules(rules);
    openMenu && dynamicMEStore.setOpenFilter(true);
    const suggestedValues = await dynamicMEStore.fetchColumnSuggestions(
      ifc_id,
      value[0]
    );
    suggestedValues && onChangeSuggestedValues(suggestedValues, index);
  };

  const clientRect = openIconRef.current?.getBoundingClientRect();

  const hasAnyPropertyRule =
    dynamicMEStore.selectedFilterByItems.rules
      .map((item) => item.property)
      .filter(Boolean).length > 0;

  return (
    <div className="overflow-visible" onClick={(e) => e.stopPropagation()}>
      <div ref={openIconRef}>
        {!hasAnyPropertyRule ? (
          <DropdownBrowser<PropertyBrowserTreeNode>
            displayKey={isEng ? "name" : "name_de"}
            detailKey={isEng ? "classification" : "classification_de"}
            handleSelect={(value) => addFirstRule(value)}
            name="filter_by"
            items={dynamicMEStore.properties}
            placeholder={t("mappingEditor.findProperty")}
            optionsClassName="!max-w-fit min-w-[246px]"
            containerClassName="relative"
            singleSelect
            fixedPos
            selectInputClassName="max-w-[134px] w-[134px]"
            titleOrIcon={<IconPlus className="h-4 w-4 cursor-pointer" />}
            isOpen={(open: boolean) => dynamicMEStore.setOpenFilter(open)}
          />
        ) : (
          <IconChevronDown
            className={clsx(
              "h-5 w-5 cursor-pointer",
              dynamicMEStore.openFilter ? "rotate-180" : ""
            )}
            onClick={handleOnOpenMenu}
          />
        )}
      </div>
      {dynamicMEStore.openFilter && hasAnyPropertyRule ? (
        <FilterMenu
          cursorPosition={{
            x: Number(clientRect?.x),
            y: Number(clientRect?.y),
          }}
          items={dynamicMEStore.properties}
          onChangeProperty={onChangeProperty}
        />
      ) : null}
    </div>
  );
});

import React from "react";
import { observer } from "mobx-react-lite";
import {
  InventoryPhysicalObjectDetailOut,
  InventoryProductOut,
  SingleItem,
} from "api-client";
import SlideOver from "components/SlideOver";
import ProductSearch from "../ProductSearch";
import { detailViewV2Store } from "store/Mapping/DetailViewStore";
import MappedProduct from "./MappedProduct";
import ButtonGroups, { BGItem } from "components/ButtonGroups";
import DetailViewV2Contents from "./DetailsViewContents";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { detailViewSkeleton } from "../utils";
import Tabs from "components/Tabs";
import EmptyState from "components/EmptyState";
import Button from "components/Button";
import { IconExternalLink } from "@tabler/icons-react";
import { NO_OBJECT_DATA_DOCS_URL } from "../consts";
import { dynamicMEStore } from "store/Mapping/DMEStore";

interface DVV2Props {
  open: boolean;
  close: () => void;
  objectDetailsList?: InventoryPhysicalObjectDetailOut[];
  object?: SingleItem;
  unmatch?: () => Promise<void>;
  refreshAfterMatching?: () => void;
  loading?: boolean;
}

type TabIds = "info-tab" | "reports-tab" | "ifc-properties";

let tabItems: BGItem[] = [
  { id: "info-tab", name: "Info", name_de: "Info" },
  { id: "reports-tab", name: "Reports", name_de: "Auswertungen" },
  {
    id: "ifc-properties",
    name: "IFC-properties",
    name_de: "IFC-Eigenschaften",
  },
];

const DetailsView = ({
  open,
  close,
  objectDetailsList,
  object,
  loading,
  unmatch,
}: DVV2Props) => {
  const [openSearch, setOpenSearch] = React.useState<boolean>(false);
  const [selectedTreeView, setSelectedTreeView] = React.useState<BGItem>(
    tabItems[0]
  );

  const location = useLocation();
  const { t } = useTranslation();

  const fetchDataAsync = async () => {
    if ((objectDetailsList?.length || object) && open) {
      await detailViewV2Store.fetchDetailViewData();
    }
  };

  React.useEffect(() => {
    if (open && objectDetailsList) {
      detailViewV2Store.resetDetailViewData();
      detailViewV2Store.setObjectId(objectDetailsList?.[0]?.id);
      onChangeDetailsTab(tabItems[0]);
    }
  }, [objectDetailsList?.[0]?.product_match, objectDetailsList?.[0]?.id, open]);

  React.useEffect(() => {
    if (open && object) {
      detailViewV2Store.resetDetailViewData();

      detailViewV2Store.setObjectId(object?.id);
      onChangeDetailsTab(tabItems[0]);
    }
  }, [open, object]);

  React.useEffect(() => {
    if (!String(location.state).toUpperCase().includes(".IFC")) {
      tabItems = tabItems.filter((item) => item.id !== "ifc-properties");
    } else if (!tabItems.find((item) => item.id === "ifc-properties")) {
      tabItems.push({
        id: "ifc-properties",
        name: "IFC-properties",
        name_de: "IFC-Eigenschaften",
      });
    }
  }, [location?.state]);

  const rootViewRef = React.useRef<HTMLDivElement>(null);
  const scrollToTop = () => rootViewRef?.current?.scrollTo({ top: 0 });

  const onChangeDetailsTab = async (e?: BGItem) => {
    if (!e) return;
    scrollToTop();
    setSelectedTreeView(e);
    switch (e.id as TabIds) {
      case tabItems[0].id:
        detailViewV2Store.setCurrentIndex(0);
        break;

      case tabItems[1].id:
        detailViewV2Store.setCurrentIndex(1);
        break;

      case tabItems[2].id:
        detailViewV2Store.setCurrentIndex(2);
        break;

      default:
        detailViewV2Store.setCurrentIndex(0);
        break;
    }
    await detailViewV2Store.setContentBasedOnTabIndex();
  };

  const [multiObjectsTabIndex, setMultiObjectsTabIndex] = React.useState(0);

  const onChangeMaterialTab = (index: number) => {
    setMultiObjectsTabIndex(index);
    onChangeDetailsTab(tabItems[0]);
    detailViewV2Store.setCurrentIndex(0);
    detailViewV2Store.setContentBasedOnTabIndex();
    detailViewV2Store.setObjectId(objectDetailsList?.[index].id);
    detailViewV2Store.resetOnObjectTabChange();
    fetchDataAsync();
  };

  function buttonGroups(isObjectTabs: boolean) {
    return (
      <div
        className={`sticky ${
          isObjectTabs ? "top-[112px]" : "top-[111px]"
        } z-30 pt-2 pb-4 bg-white`}
      >
        <ButtonGroups
          disabled={detailViewV2Store.loading}
          containerClassName="cursor-pointer"
          items={tabItems}
          onChange={onChangeDetailsTab}
          current={selectedTreeView}
        />
      </div>
    );
  }

  function mappedProductContents() {
    const product = dynamicMEStore.getCurrentRowObject()
      ?.product_match as InventoryProductOut;

    return (
      <MappedProduct
        className="border-b-0"
        inputClassName={"bg-white"}
        label={t("detailView.infoTab.mappedBuildingProduct")}
        product={
          objectDetailsList?.length
            ? objectDetailsList[0].product_match
            : product
        }
        object={objectDetailsList?.length ? objectDetailsList[0] : null}
        unmatch={unmatch}
        onClickSearch={() => setOpenSearch?.(true)}
        showTooltip={false}
      />
    );
  }

  function materialsTabContent(): React.ReactNode {
    const tabOptions =
      objectDetailsList?.map((rec: InventoryPhysicalObjectDetailOut) => ({
        item: rec.material as string,
        content: (
          <>
            <div className="bg-gray-50 border-b border-t p-4 sticky top-0 z-30">
              {mappedProductContents()}
            </div>
            <div className="px-4">
              {buttonGroups(true)}
              <DetailViewV2Contents
                object={rec}
                onReloadReportTiles={fetchDataAsync}
              />
            </div>
          </>
        ),
      })) ?? [];

    return (
      <Tabs
        currentIndex={multiObjectsTabIndex}
        options={tabOptions}
        listClassName={
          "h-[52px] px-3 pl-5 border-b border-gray-300 bg-white z-30"
        }
        containerClassName="!px-0 border-none !relative"
        onChange={onChangeMaterialTab}
        showArrows
      />
    );
  }

  function content() {
    const isMultipleMaterialObject =
      objectDetailsList && objectDetailsList?.length > 1;
    const isSingleObject = objectDetailsList?.length === 1 || object;

    if (isMultipleMaterialObject) return materialsTabContent();
    if (isSingleObject || loading) {
      const currentObject = objectDetailsList?.length
        ? objectDetailsList[0]
        : object;

      return (
        <div>
          <div className="bg-gray-50 border-b p-4 sticky top-0 z-30">
            {mappedProductContents()}
          </div>
          <div className="px-4">
            {buttonGroups(false)}
            {detailViewV2Store.loading ? (
              detailViewSkeleton()
            ) : (
              <DetailViewV2Contents
                object={currentObject}
                onReloadReportTiles={fetchDataAsync}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <EmptyState
        type="data"
        noBorder
        title={t("emptyState.objectDataNotAvailable")}
        alertText={t("emptyState.objectDataNotAvailableAlert")}
        alertType="info"
        button={
          <Button
            type="secondary"
            width="fit-content"
            trailingIcon={<IconExternalLink />}
            onClick={() =>
              window.open(
                NO_OBJECT_DATA_DOCS_URL,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {t("emptyState.showDocs")}
          </Button>
        }
        containerClassName="h-full"
      />
    );
  }

  return (
    <>
      <SlideOver
        open={open}
        close={close}
        containerClassName="sm:top-[69px] w-[480px] shadow-slate-400"
        title={
          <span className="break-all !w-[300px]">
            {objectDetailsList?.length
              ? objectDetailsList[0]?.component
              : detailViewV2Store.infoTileData?.component_name}
          </span>
        }
        className="!p-0"
      >
        <div
          ref={rootViewRef}
          className="overflow-y-auto max-h-[calc(100vh-120px)] relative z-30 h-full"
        >
          {content()}
        </div>
      </SlideOver>
      {openSearch && (
        <ProductSearch
          open={openSearch}
          close={() => setOpenSearch(false)}
          currentRow={objectDetailsList?.[0]}
          isDetailVeiw
        />
      )}
    </>
  );
};

export default observer(DetailsView);

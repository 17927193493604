import React from "react";
import {
  IconDatabaseOff,
  IconExclamationMark,
  IconFilterOff,
  IconHelp,
  IconInfoCircle,
  IconReload,
} from "@tabler/icons-react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useUserback } from "@userback/react";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { useParams } from "react-router";

const EmptyState = () => {
  const { t } = useTranslation();
  const userback = useUserback();
  const { ifc_id } = useParams();

  const reloadTable = () => dynamicMEStore.fetchObjectsList(0, true, ifc_id);

  return (
    <div className="flex flex-col items-center h-full justify-center">
      {dynamicMEStore.errorFetchingObjects ? (
        <div className="flex flex-col items-center -mt-28">
          <IconDatabaseOff className="text-gray-200" size={76} />
          <span className="text-xs text-gray-500 mt-4 mb-8">
            {t("mappingEditor.noObjetcsFromServer")}
          </span>
          <div className="flex mr-2 text-xs text-red-700 items-center max-w-[500px]">
            <IconExclamationMark
              className="bg-red-100 rounded-full p-1 mr-2 text-red-600 min-w-[25px]"
              stroke={3}
            />
            {t("mappingEditor.serverErrorText")}
          </div>
          <div className="flex mt-8">
            <Button
              type="primary"
              leadingIcon={<IconReload />}
              onClick={reloadTable}
              className="mr-6"
              width="fit-content"
            >
              {t("mappingEditor.reload")}
            </Button>
            <Button
              type="secondary"
              leadingIcon={<IconHelp />}
              onClick={() => userback.open("bug", "screenshot")}
            >
              {t("mappingEditor.support")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center -mt-28">
          <IconFilterOff className="text-gray-200" size={57} />
          <span className="text-xs text-gray-500 mt-4 mb-8">
            {t("mappingEditor.noObjectsFiltered")}
          </span>
          <div className="flex mr-2 text-xs text-indigo-700 items-center">
            <IconInfoCircle
              className="bg-indigo-100 rounded-full p-1 mr-2"
              stroke={3}
            />
            {t("mappingEditor.adjustFiltersText")}
          </div>
          <div className="flex mt-8">
            <Button
              leadingIcon={<IconFilterOff />}
              onClick={() => dynamicMEStore.removeAllFilteringRules()}
            >
              {t("mappingEditor.removeFilterRules")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(EmptyState);

import React from "react";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/Mapping/SearchStore";
import ProductSearch from "../ProductSearch";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { SingleItem } from "api-client";
import MEObjectDetail from "../ObjectDetailsSlideOver";
import { useParams } from "react-router";
import PanelLayout from "./layout/PanelLayout";
import ProductDetailsPopup from "../ProductDetailsPopup";
import { buildingProductStore } from "store/Mapping/BuildingProductStore";
import useMappingEditor from "./useMappingEditor";
import ConfirmModal from "components/ConfirmModal";
import { useTranslation } from "react-i18next";

const DynamicMappingEditor = observer(() => {
  const { ifc_id } = useParams();
  const { mappingToFile, mappingToObjects } = useMappingEditor();
  const { t } = useTranslation();

  React.useEffect(() => {
    dynamicMEStore.getFileCalculationsStatus(ifc_id);
    buildingProductStore.fetchCPXValues();

    const interval = setInterval(
      () => {
        dynamicMEStore.getFileCalculationsStatus(ifc_id);
      },
      dynamicMEStore.fileCalculationsStatus ? 5000 : 10000
    );

    return () => clearInterval(interval);
  }, []);

  async function matchProductAndUpdate(
    product_id: string,
    current_object: SingleItem
  ) {
    if (dynamicMEStore.selectAllChecked && !current_object) {
      await mappingToFile("manual_match", product_id);
    } else {
      await mappingToObjects(
        "manual_match",
        product_id,
        dynamicMEStore.matchForSelectedItems ? undefined : current_object
      );
    }
  }

  const onCloseObjectDetailView = () => {
    dynamicMEStore.setOpenedObjectDetails(false);
    dynamicMEStore.setCurrentRow(undefined);
  };

  const onCloseProductDetailView = () => {
    dynamicMEStore.setOpenProductDetailsPopup({ open: false });
  };

  return (
    <div className="flex-grow">
      <PanelLayout />
      {searchStore.openProductSearch && (
        <ProductSearch
          open={searchStore.openProductSearch}
          close={() => searchStore.setOpenProductSearch(false)}
          currentRow={dynamicMEStore.currentRow?.original as SingleItem}
          matchProductAndUpdate={matchProductAndUpdate}
        />
      )}
      <MEObjectDetail
        open={dynamicMEStore.openedObjectDetails}
        close={onCloseObjectDetailView}
        object={dynamicMEStore.getCurrentRowObject()}
      />
      <ProductDetailsPopup
        productId={dynamicMEStore.openProductDetailsPopup.id}
        productName={dynamicMEStore.openProductDetailsPopup.name}
        productType={dynamicMEStore.openProductDetailsPopup.type}
        open={dynamicMEStore.openProductDetailsPopup.open}
        close={onCloseProductDetailView}
      />
      <ConfirmModal
        open={dynamicMEStore.isConfirmationVisible}
        close={() => dynamicMEStore.cancelMappingToFile()}
        title={t("mappingEditor.fileMappingConfirmTitle")}
        descriptionMesage={t("mappingEditor.fileMappingConfirmDescription")}
        onConfirm={() => dynamicMEStore.confirmMappingToFile()}
        cancelTitle={t("commons.cancel")}
        confirmButtonTitle={t("mappingEditor.changeMappings")}
      />
    </div>
  );
});

export default React.memo(DynamicMappingEditor);

import React, { useState, useRef, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "@heroicons/react/outline";
import moment from "moment";
import InputLabel from "components/InputLabel";
import clsx from "clsx";
import { PopperPlacementType } from "@material-ui/core";

type Props = {
  label: string;
  value?: string;
  containerClassName?: string;
  dateFormat?: string;
  placeholder?: string;
  name?: string;
  monthYearPicker?: boolean;
  yearPicker?: boolean;
  minDate?: string;
  maxDate?: string;
  popperPlacement?: "top" | "top-start" | "bottom" | "auto";
  onChange?: (value: string | undefined | Date, name: string) => void;
  onChangeDgnb?: (value: string, name: string) => void;
  isDgnb?: boolean;
};

const CustomDatePicker: React.FC<Props> = ({
  label,
  value,
  dateFormat,
  containerClassName,
  onChange,
  onChangeDgnb,
  placeholder,
  monthYearPicker = false,
  yearPicker,
  minDate,
  maxDate,
  name = "",
  isDgnb,
  popperPlacement = "bottom",
}) => {
  const [date, setDate] = useState<Date | undefined>();

  useEffect(() => {
    !date &&
      setDate(moment(value).isValid() ? moment(value).toDate() : undefined);
  }, [value]);

  const handleOnChange = (date: Date | null) => {
    if (!date) return;
    setDate(date);
    if (isDgnb) {
      const dateValue = moment(date).isValid()
        ? moment(date).format(getFormat())
        : "";
      onChangeDgnb && onChangeDgnb(dateValue, name);
    } else {
      onChange && onChange(date, name);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<ReactDatePicker>(null);

  const handleIconClick = () => {
    inputRef.current?.focus();
  };

  function getFormat() {
    if (dateFormat && monthYearPicker) {
      return "MM.YYYY";
    }
    return dateFormat && yearPicker ? "YYYY" : "DD.MM.YYYY";
  }

  const validateDateValue = () => {
    if (isDgnb && value?.includes("GMT+")) {
      return moment(value).format(getFormat());
    }
    if (value && isDgnb) {
      return value;
    }
    if (moment(value).isValid()) {
      return moment(value).format(getFormat());
    }
    return "";
  };

  return (
    <div className={`flex flex-col w-full ${containerClassName}`}>
      <InputLabel labelName={label} name={label?.replaceAll(" ", "_")} />
      <ReactDatePicker
        popperPlacement={popperPlacement as PopperPlacementType}
        selected={date}
        name={name}
        onChange={handleOnChange}
        dateFormat={dateFormat}
        showMonthYearPicker={monthYearPicker}
        showYearPicker={yearPicker}
        ref={datePickerRef}
        minDate={minDate ? moment(minDate).toDate() : undefined}
        maxDate={maxDate ? moment(maxDate).toDate() : undefined}
        customInput={
          <div className="flex items-center">
            <input
              ref={inputRef}
              type="text"
              className={clsx(
                "w-full py-2 pr-10 pl-3 placeholder:text-gray-500 text-gray-700 bg-white border",
                "border-gray-300 focus:ring-0 focus:border-indigo-500 rounded-md shadow-sm focus:outline-none text-sm"
              )}
              placeholder={placeholder}
              value={validateDateValue()}
              readOnly
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-r-md hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-t-indigo-500 focus:border-r-indigo-500 focus:border-b-indigo-500"
              onClick={handleIconClick}
            >
              <CalendarIcon className="h-5 w-5" />
            </button>
          </div>
        }
      />
    </div>
  );
};

export default CustomDatePicker;

import React from "react";
import { IconInfoCircle, IconTrash } from "@tabler/icons-react";
import { componentsStore } from "store/Mapping/ComponentsStore";
import clsx from "clsx";
import ProductComponentInfo from "features/MappingTools/ProductComponentInfo";
import { observer } from "mobx-react-lite";
import { ComponentDetailsByFileOut } from "api-client";
import ProductDetailsPopup from "../ProductDetailsPopup";

const ComponentChildContent = ({
  childItem,
  removable,
  hideInfoIcon,
  className,
}: {
  childItem: ComponentDetailsByFileOut;
  removable?: boolean;
  hideInfoIcon?: boolean;
  className?: string;
}) => {
  const [openDetails, setOpenDetails] = React.useState(false);
  const removeRow = (child: ComponentDetailsByFileOut) => {
    componentsStore.onChangeData(
      "childrenList",
      componentsStore.data.childrenList.filter((item) => child != item)
    );
    componentsStore.getComponentCompliance();
    componentsStore.getComponentDINCategory();
  };

  return (
    <div
      className={clsx("bg-white flex justify-between p-3 w-full", className)}
    >
      <ProductComponentInfo item={childItem} />
      <div className="flex">
        {hideInfoIcon ? null : (
          <IconInfoCircle
            className="text-gray-500 hover:text-gray-700 w-6 h-6 cursor-pointer"
            onClick={() => setOpenDetails(true)}
          />
        )}
        {removable ? (
          <IconTrash
            className="text-red-700 hover:text-red-500 cursor-pointer w-6 h-6 ml-1.5 mr-0"
            onClick={() => removeRow(childItem)}
          />
        ) : null}
      </div>
      <ProductDetailsPopup
        productId={childItem.id}
        productName={childItem.name}
        productType={childItem.type}
        open={openDetails}
        close={() => setOpenDetails(false)}
      ></ProductDetailsPopup>
    </div>
  );
};

export default observer(ComponentChildContent);

import Modal, { ModalProps } from "components/Modal";
import React from "react";
import CheckCircleIcon from "assets/images/check-circle-green.svg";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";
import LoadingIcon from "components/LoadingIcon";
import { useNavigate } from "react-router";
import { IconArrowRight } from "@tabler/icons-react";

const GeneratePDFProgressModal = (props: ModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const generatePDFExport = async () => {
    await buildingStore.getPPDFReport();
    setLoading(false);
  };

  const navigateExports = () => {
    navigate("exports");
    props.close();
  };

  React.useEffect(() => {
    props.open && generatePDFExport();
    return () => setLoading(true);
  }, [props.open]);

  return (
    <Modal
      title={loading ? t("exports.exportLifeCyclePass") : null}
      isOpen={props.open}
      setIsOpen={() => props.close()}
      containerClassName="min-w-[640px]"
      closeButton={loading}
    >
      {loading ? (
        <div className="flex flex-col justify-center items-center p-6">
          <div className="text-xs font-medium text-gray-500">
            {t("exports.pdfGenerating")}
          </div>
          <LoadingIcon className="min-w-[100px] min-h-[100px] mt-6" />
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center items-center p-6">
          <div className="space-y-4 text-center items-center flex flex-col justify-center w-full">
            <img
              className="w-[64px]"
              src={CheckCircleIcon}
              alt="check-circle"
            />
            <label className="font-[600] text-lg text-gray-900">
              {t("exports.pdfGeneratedSuccessMSG")}
            </label>
          </div>
          <div className="font-normal text-sm text-gray-700 mt-2">
            {t("exports.pdfSuccessMSGDesc")}
          </div>

          <div className="flex justify-between w-full gap-6 mt-6">
            <Button type="gray" onClick={props.close}>
              {t("exports.seeLater")}
            </Button>
            <Button
              type="primary"
              onClick={navigateExports}
              trailingIcon={<IconArrowRight />}
            >
              {t("exports.openExports")}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default observer(GeneratePDFProgressModal);

import React from "react";
import {
  Cell,
  Column,
  ColumnSizingState,
  Header,
  Table,
  flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import {
  IconChevronDown,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";
import Filter from "./FeFilter";
import { CustomFilterProps } from "./types";
import useOutsideClick from "hooks/useOutsideClick";

export default function HeaderColumn({
  table,
  header,
  headerIndex,
  enableColumnResizing,
  hasGroupHeader,
  borderedStyle,
  headerColumnStyle,
  headerDivStyle,
  setColumnSizing,
  groupHeaderClassName,
  deviderIndexes,
  headerMenuComponent,
}: {
  table: Table<unknown>;
  header: Header<unknown, unknown>;
  headerIndex: number;
  enableColumnResizing?: boolean;
  hasGroupHeader?: boolean;
  borderedStyle?: boolean;
  headerColumnStyle?: string;
  headerDivStyle?: string;
  setColumnSizing?: React.Dispatch<React.SetStateAction<ColumnSizingState>>;
  groupHeaderClassName?: string;
  deviderIndexes?: number[];
  headerMenuComponent?: (column: Column<unknown, unknown>) => React.ReactNode;
}) {
  const [doubleClicked, setDoubleClicked] = React.useState(false);
  const [openHeaderMenu, setOpenHeaderMenu] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick(menuRef, () => setOpenHeaderMenu(false));

  function onClickHeaderColumn(
    header: Header<unknown, unknown>
  ): React.MouseEventHandler<HTMLDivElement> | undefined {
    return () =>
      header.column.getCanSort() &&
      header.column.toggleSorting(header.column.getIsSorted() !== "desc");
  }

  function onDoubleClick() {
    setDoubleClicked(true);
  }

  function resizerHandle() {
    if (
      !enableColumnResizing ||
      (header.column.columnDef as CustomFilterProps).disableResizing
    )
      return null;
    return (
      <div
        onDoubleClick={onDoubleClick}
        onMouseDown={header.getResizeHandler()}
        onTouchStart={header.getResizeHandler()}
        className={clsx({
          resizer:
            enableColumnResizing &&
            !(header.column.columnDef as CustomFilterProps).disableResizing,
          "is-resizing": header.column.getIsResizing(),
        })}
      />
    );
  }

  function columnSortingContent(header: Header<unknown, unknown>) {
    const iconClassNames = "ml-1 h-4 min-w-[20px] stroke-gray-500";
    return (
      {
        asc: <IconSortAscending className={iconClassNames} />,
        desc: <IconSortDescending className={iconClassNames} />,
      }[header.column.getIsSorted() as string] ?? (
        <IconSortDescending
          className={clsx(
            iconClassNames,
            "opacity-40 transition delay-300 invisible group-hover:visible"
          )}
        />
      )
    );
  }

  function columnFilteringContent(header: Header<unknown, unknown>) {
    if ((header.column.columnDef as CustomFilterProps).customFilter) {
      return (header.column.columnDef as CustomFilterProps).customFilter;
    }
    if (
      header.column.getCanFilter() &&
      !(header.column.columnDef as CustomFilterProps).customFilter
    ) {
      return <Filter column={header.column} table={table} />;
    }
    return <></>;
  }

  const minWidth = (header.column.columnDef as CustomFilterProps).minWidth;

  const getTDStyle = () => {
    const defaultWidth = (header.column.columnDef as CustomFilterProps)
      .defaultWidth;
    const style = {
      width: doubleClicked
        ? defaultWidth
        : header.getSize() < Number(minWidth)
        ? minWidth
        : header.getSize(),
      minWidth: doubleClicked
        ? defaultWidth
        : header.getSize() > Number(minWidth)
        ? header.getSize()
        : minWidth ?? "auto",
      maxWidth: (header.column.columnDef as CustomFilterProps).disableResizing
        ? (header.column.columnDef as CustomFilterProps).maxWidth ??
          header.column.getSize()
        : "auto",
    };
    if (doubleClicked) {
      setDoubleClicked(false);
      defaultWidth
        ? setColumnSizing?.((state) => ({
            ...state,
            [header.id]: Number(defaultWidth),
          }))
        : header.column.resetSize();
    }
    return style;
  };

  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      style={getTDStyle()}
      className={clsx(
        "h-auto border-gray-300 border-b p-0",
        headerColumnStyle,
        isColumnSticky(header, "bg-gray-50"),
        {
          "py-1 text-xs border-r last-of-type:border-r-0":
            header.depth === 1 && hasGroupHeader,
          "border-r last-of-type:border-r-0":
            header.depth === 2 && hasGroupHeader,
          "!border-gray-300 border-r last-of-type:border-r-0 ":
            header.column.id === "select" ||
            deviderIndexes?.includes(headerIndex),
        },
        (header.column.columnDef as CustomFilterProps).headerClassName
      )}
    >
      <div className="flex items-center">
        {header.isPlaceholder ? null : (
          <div
            className={clsx(
              "flex-row relative h-full w-full text-left align-top text-sm font-medium",
              "text-gray-900 border-gray-300 items-center flex justify-between",
              borderedStyle ? "p-4" : "px-2 py-3",
              headerDivStyle,
              header.depth === 1 && hasGroupHeader ? groupHeaderClassName : ""
            )}
          >
            {resizerHandle()}
            <div className="w-full">
              <div
                className={clsx("flex items-center relative w-full", {
                  "cursor-pointer select-none group":
                    header.column.getCanSort(),
                })}
                onClick={onClickHeaderColumn(header)}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {header.column.getCanSort() && columnSortingContent(header)}
              </div>
              {columnFilteringContent(header)}
            </div>
            {(header.column.columnDef as CustomFilterProps).enableMenu && (
              <div className="relative">
                <IconChevronDown
                  size={22}
                  className={clsx(
                    "border border-gray-300 p-0.5 rounded-[4px] text-gray-900 cursor-pointer",
                    "hover:bg-gray-200 ml-1"
                  )}
                  onClick={() => setOpenHeaderMenu(!openHeaderMenu)}
                />
                {openHeaderMenu && (
                  <div
                    ref={menuRef}
                    className={clsx(
                      "absolute top-6 left-0 rounded-md bg-white border border-gray-300",
                      "z-10 text-xs whitespace-nowrap overflow-hidden"
                    )}
                  >
                    {headerMenuComponent?.(header.column)}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </th>
  );
}

export function isColumnSticky(
  cell: Cell<unknown, unknown> | Header<unknown, unknown>,
  bgColor: string
) {
  return (cell.column.columnDef as CustomFilterProps).isSticky
    ? `sticky left-0 z-1 ${bgColor} ${
        (cell.column.columnDef as CustomFilterProps).stickyClass
      }`
    : "";
}

import React from "react";
import GeneralInformationStep from "./Steps/GeneralInformationStep";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import {
  CustomProduct,
  buildingProductStore,
} from "store/Mapping/BuildingProductStore";
import clsx from "clsx";
import EPDdataStep from "./Steps/EPDdataStep";
import CircularityStep from "./Steps/CircularityStep";
import { observer } from "mobx-react-lite";
import ProductEditorFooter from "./ProductEditorFooter";
import { useLocation } from "react-router";
import { StepperCustomCircle } from "utils";
import { EPDGroupOUT, EPDSOUT, ProductSearchOutput } from "api-client";
import { searchStore } from "store/Mapping/SearchStore";
import { getCurrentReferenceValue } from "../utils";

export default observer(function BuildingProductEditor() {
  const { steps, activeStep } = buildingProductStore;
  const location = useLocation();

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <GeneralInformationStep />;
      case 1:
        return <CircularityStep />;
      case 2:
        return <EPDdataStep />;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    buildingProductStore.setActiveStep(0);
    (async () => {
      await getListItems();
      if (location.state) {
        loadDataForUpdate();
        buildingProductStore.checkSaveValidity();
      } else {
        buildingProductStore.resetData();
      }
    })();

    return () => buildingProductStore.resetData();
  }, []);

  async function getListItems() {
    await buildingProductStore.fetchCPXValues();
    await buildingProductStore.getDinCategoriesLevel1();
  }

  const normalizeGroup = (group: EPDGroupOUT) => {
    return group.epds?.map((item: EPDSOUT) => {
      return {
        ...item,
        factor: item.factor,
        epd: { ...item.epd, dataset_source__name: item.epd.dataset_source },
      };
    });
  };

  function loadDataForUpdate() {
    const currentItem: ProductSearchOutput | undefined =
      searchStore.searchResults.items.find(
        (item) => item.id === location.state.id
      );
    if (!currentItem) return;
    buildingProductStore.setData({
      id: currentItem?.id,
      name: currentItem?.name,
      dataset_source: currentItem?.dataset_source,
      groups: currentItem.groups?.map((group) => ({
        conformity: group.conformity,
        certifications: group.certifications,
        epds: normalizeGroup(group),
      })),
      auto_mapping_tags: currentItem.tags_automapper,
      din_category: buildingProductStore.optionsLists.din_category?.find(
        (item) =>
          item.name ===
          `${currentItem?.din_category_number} ${currentItem?.din_category}`
      )?.id,
      life_span: currentItem.life_span,
      is_external: currentItem.is_external,
      is_load_bearing: currentItem.is_load_bearing,
      separability_score: undefined,
      deconstructability_score: undefined,
      reusability_score: undefined,
      recyclability_score: undefined,
      recyclability_qualification: currentItem?.recyclability_qualification,
      deconstructability_qualification:
        buildingProductStore.optionsLists.deconstructability_qualification.find(
          (item) =>
            item.name?.includes(
              currentItem?.deconstructability_qualification as string
            )
        )?.id,
      separability_qualification:
        buildingProductStore.optionsLists.separability_qualification.find(
          (item) => item.name === currentItem?.separability_qualification
        )?.id,
      reusability_qualification:
        buildingProductStore.optionsLists.reusability_qualification.find(
          (item) => item.name === currentItem?.reusability_qualification
        )?.id,
      preuse_qualification:
        buildingProductStore.optionsLists.preuse_qualification.find(
          (item) => item.name === currentItem?.preuse_qualification
        )?.id,
      reference_unit: getCurrentReferenceValue(
        currentItem.reference_unit.toUpperCase()
      ),
    } as CustomProduct);
  }

  return (
    <div className="flex w-full relative">
      <div className="pt-4 px-6 pb-10 relative overflow-y-scroll max-h-[calc(100vh-114px)] w-full">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ backgroundColor: "transparent" }}
        >
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel
                color="black"
                StepIconComponent={StepperCustomCircle}
              />
            </Step>
          ))}
        </Stepper>
        <div
          className={clsx("overflow-y-scroll", {
            "overscroll-y-auto": activeStep === 1 || activeStep === 2,
          })}
        >
          {renderStepContent()}
        </div>
      </div>
      <ProductEditorFooter />
    </div>
  );
});

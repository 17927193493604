import React from "react";
import { useMappingTools } from "../useMappingTools";
import { useTranslation } from "react-i18next";
import {
  FIELDSSET_TITLE_CLASSES,
  FIELD_LABEL_CLASSES,
  FIELD_VALUE_CLASSES,
} from "../consts";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { getCurrentReferenceValue } from "../utils";
import { searchStore } from "store/Mapping/SearchStore";
import clsx from "clsx";
import Tooltip from "components/Tooltip";

const CommonDetails = (props: { className?: string }) => {
  const { productAdditionalInfo, productCircularityInfo } = useMappingTools();
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getCertificationChipsGroup, getConformityChipsGroup } =
    useComplianceInfo();

  function generalInformation() {
    return (
      <div>
        <div className={FIELDSSET_TITLE_CLASSES}>
          {t("mapping.generalInformation")}
        </div>
        <div className="flex flex-wrap justify-start">
          {productAdditionalInfo(searchStore.currentDetailNode, true).map(
            (item, index) => (
              <div key={index} className="mb-2 pr-4 last-of-type:pr-0">
                <div className={FIELD_LABEL_CLASSES}>{item.label}</div>
                <div className={FIELD_VALUE_CLASSES}>{item.value}</div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  function complianceInformation() {
    return (
      <div className="pl-6">
        <div className={FIELDSSET_TITLE_CLASSES}>{t("mapping.conformity")}</div>
        <div className="text-sm font-medium mb-2 text-gray-600">
          {t("mapping.cetification_desc")}
        </div>
        <div className="mb-3">
          {getCertificationChipsGroup(
            searchStore.currentDetailNode?.compliance?.certifications
          )}
        </div>
        <div className="text-sm font-medium mb-2 text-gray-600">
          {t("mapping.conformity_desc")}
        </div>
        {getConformityChipsGroup(
          searchStore.currentDetailNode?.compliance?.conformities
        )}
      </div>
    );
  }

  function circularityInformation() {
    if (searchStore.currentDetailNode?.type === "COMPONENT") return;
    return (
      <div className="w-full">
        <div className={FIELDSSET_TITLE_CLASSES}>
          {t("productEditor.circularity")}
        </div>
        <div className="flex flex-wrap justify-start">
          {productCircularityInfo(searchStore.currentDetailNode).map(
            (rec, index) => (
              <div key={index} className="mb-2 pr-4 last-of-type:pr-0">
                <div className={FIELD_LABEL_CLASSES}>{rec.label}</div>
                <div className={FIELD_VALUE_CLASSES}>
                  <span>{rec.value || "-"}</span>
                  {rec.value && (
                    <Tooltip
                      content={`${t("productEditor.score")}: ${rec.score}`}
                      className="text-gray-500"
                    />
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  function referenceUnit() {
    const referenceUnit = getCurrentReferenceValue(
      searchStore.currentDetailNode?.reference_unit.toUpperCase()
    );

    return (
      <div>
        <div className={FIELDSSET_TITLE_CLASSES}>
          {t("mapping.referenceUnitAndEnvironmentalData")}
        </div>
        <div className={FIELD_LABEL_CLASSES}>{t("mapping.referenceUnit")}</div>
        <div className={FIELD_VALUE_CLASSES}>
          {isEng ? referenceUnit?.name ?? "-" : referenceUnit?.name_de ?? "-"}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={clsx("grid grid-cols-2", props.className)}>
        {generalInformation()}
        {complianceInformation()}
      </div>
      <div>
        {circularityInformation()}
        {referenceUnit()}
      </div>
    </div>
  );
};

export default CommonDetails;

import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SimpleSelect from "components/SimpleSelect";
import {
  Option,
  buildingProductStore,
} from "store/Mapping/BuildingProductStore";
import Alert from "components/Alert";
import { componentsStore } from "store/Mapping/ComponentsStore";

const Step3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-lg font-semibold mb-2">
        {t("productEditor.circularity")}
      </div>
      <div className="text-sm font-normal text-gray-700 mb-6">
        {t("components.circularityDescription")}
      </div>
      <Alert
        description={t("components.circularityAlert")}
        type="info"
        className="mt-3 mb-6"
      />
      <div className="bg-white border-gray-300 border p-6 rounded-md">
        <div className="border-b border-gray-100 pb-3">
          <div className="text-base font-semibold mb-3">
            {t("productEditor.preUse")}
          </div>
          <SimpleSelect
            options={
              buildingProductStore.optionsLists.preuse_qualification as Option[]
            }
            labelName={t("productEditor.material_origin")}
            placeholder={t("components.selectValue")}
            name="preuse_qualification"
            onChange={(value, name) =>
              componentsStore.onChangeData(name as string, value)
            }
            current={componentsStore.data.preuse_qualification}
            noMargin
            clearOption
            containerClassName="mb-2"
          />
          <span className="text-sm text-gray-500">
            {t("components.material_origin_desc")}
          </span>
        </div>
        <div className="mt-4">
          <div className="text-base font-semibold mb-3">
            {t("productEditor.postUse")}
          </div>
          <div className="w-full justify-between block lg:grid grid-cols-2 gap-4">
            <div className="mb-6 lg:mb-0">
              <SimpleSelect
                options={
                  buildingProductStore.optionsLists
                    .deconstructability_qualification as Option[]
                }
                labelName={t("productEditor.deconstructability")}
                placeholder={t("components.selectValue")}
                name="deconstructability_qualification"
                onChange={(value, name) =>
                  componentsStore.onChangeData(name as string, value)
                }
                current={componentsStore.data.deconstructability_qualification}
                noMargin
                required
                containerClassName="mb-2"
              />
              <span className="text-sm text-gray-500">
                {t("components.deconstructability_desc")}
              </span>
            </div>
            <div>
              <SimpleSelect
                options={
                  buildingProductStore.optionsLists
                    .reusability_qualification as Option[]
                }
                labelName={t("productEditor.reusability")}
                placeholder={t("components.selectValue")}
                name="reusability_qualification"
                onChange={(value, name) =>
                  componentsStore.onChangeData(name as string, value)
                }
                current={componentsStore.data.reusability_qualification}
                noMargin
                clearOption
                containerClassName="mb-2"
              />
              <span className="text-sm text-gray-500">
                {t("components.reusability_desc")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Step3);

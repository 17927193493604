import { ColumnDef, Row, SortingState } from "@tanstack/react-table";
import Modal, { ModalProps } from "components/Modal";
import SearchSortTable from "components/Table";
import NotFound from "components/NotFound";
import SearchBar from "features/MappingTools/ProductSearch/SearchBar";
import React from "react";
import { buildingProductStore } from "store/Mapping/BuildingProductStore";
import useColumns from "./useColumns";
import { observer } from "mobx-react-lite";
import SearchFilters, { Filters } from "../SearchFilters";
import { MaterialSearchOUT, MaterialSearchSortBy, SortOrder } from "api-client";

interface ProductEditorMaterialSearch extends ModalProps {
  datasetIndex: number;
  rowIndex: number;
}

const PAGE_SIZE = 25;

export default observer(function ProductEditorMaterialSearch(
  props: ProductEditorMaterialSearch
) {
  const [term, setTerm] = React.useState<string>("");
  const [filters, setFilters] = React.useState<Filters>({
    material_category: [],
  });
  const [sorting, setSorting] = React.useState<{
    sordOrder: SortOrder | null;
    sortBy: MaterialSearchSortBy | null;
  }>({ sordOrder: null, sortBy: null });

  const onSearch = (value: string | number) => {
    setTerm(String(value));
  };
  const materials = buildingProductStore.materials;

  const onSelectRow = (row: Row<unknown>) => {
    buildingProductStore.removeMaterialErrors(
      props.datasetIndex,
      props.rowIndex
    );
    buildingProductStore.onChangeDataset(
      "material",
      row?.original as MaterialSearchOUT,
      props.datasetIndex,
      props.rowIndex
    );
    props.close();
  };

  const selectedRowID = () => {
    return (
      buildingProductStore.data.groups?.[props.datasetIndex]?.epds?.[
        props.rowIndex
      ].material as MaterialSearchOUT
    )?.id;
  };

  React.useEffect(() => {
    return () => buildingProductStore.resetMaterials();
  }, []);

  React.useEffect(() => {
    if (props.open && !buildingProductStore.materials.loading)
      (async () => await fetchData(0))();
  }, [term, filters, sorting]);

  function onSortChanged(sorting: SortingState) {
    const sortBy = sorting?.length ? sorting[0]?.id ?? null : null;
    const sortDirection = sorting?.length
      ? sorting[0]?.desc
        ? "DESC"
        : "ASC"
      : null;
    setSorting({
      sortBy: sortBy as MaterialSearchSortBy,
      sordOrder: sortDirection,
    });
  }

  async function fetchData(start: number) {
    buildingProductStore.setMaterials({
      ...buildingProductStore.materials,
      loading: true,
    });
    const pageNumber = start / PAGE_SIZE + 1;
    await buildingProductStore
      .fetchMaterial(
        pageNumber,
        term,
        filters.material_category?.map((item) => item.name) as string[],
        sorting.sordOrder,
        sorting.sortBy
      )
      .then((receivedData) => {
        if (receivedData?.items) {
          buildingProductStore.setMaterials({
            count: receivedData.count,
            items: [
              ...(start ? buildingProductStore.materials.items : []),
              ...receivedData.items,
            ],
            loading: false,
          });
        }
      });
  }

  function updateFilters(name: string, values: unknown[]) {
    setFilters((state) => ({ ...state, [name]: values }));
  }

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      className="p-6"
      containerClassName="md:min-w-[1050px] min-h-[500px] max-h-[calc(100vh-100px)] max-h-fit"
      position="top"
    >
      <SearchBar
        value={term}
        onChange={onSearch}
        onCancel={() => props.close()}
      />
      <SearchFilters filters={filters} setFilters={updateFilters} />
      {materials.items.length || materials.loading ? (
        <SearchSortTable
          borderedStyle
          getColumns={useColumns as () => ColumnDef<unknown, unknown>[]}
          data={materials.items}
          onRowClick={onSelectRow}
          selectedRow={selectedRowID()}
          hasHoverStyle
          zebraStriped
          fetchData={fetchData}
          totalDBRowCount={materials.count}
          scrollDivClassName="!max-h-[calc(100vh-350px)]"
          skeletonClassName="!max-h-[calc(100vh-350px)]"
          dataFetchLoading={materials.loading}
          headerColumnStyle="!h-[60px]"
          skeletonLoading={materials.loading && materials.count === 0}
          fullHeightSkeleton
          onSortChanged={onSortChanged}
        />
      ) : (
        <NotFound />
      )}
    </Modal>
  );
});

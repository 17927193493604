import React from "react";
import { SimpleMaterialOut } from "api-client";
import DropdownCheckbox from "components/DropdownCheckbox";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { searchStore } from "store/Mapping/SearchStore";

export default observer(function MaterialFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: SimpleMaterialOut[];
  setSelected: React.Dispatch<React.SetStateAction<SimpleMaterialOut[]>>;
  removingItem?: SimpleMaterialOut;
  handleRemoveItem?: () => void;
}) {
  const onChangeCategories = (value: SimpleMaterialOut[]) => setSelected(value);

  React.useEffect(() => {
    (async () => {
      const items = await dynamicMEStore.getMaterials();
      items &&
        searchStore.setFilterOptions({
          ...searchStore.filtersOptions,
          materials: items.filter((item) => item.name !== ""),
        });
    })();
  }, []);

  if (!searchStore.filtersOptions.materials) return <></>;
  return (
    <DropdownCheckbox<SimpleMaterialOut>
      displayKey={"name"}
      required={true}
      handleSelect={onChangeCategories}
      labelName={"Material"}
      name="materials"
      items={searchStore.filtersOptions.materials}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px]"
      optionsClassName="z-40 !max-w-fit"
      checkedItems={selected}
      noMargin
    />
  );
});

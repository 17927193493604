import React from "react";
import { observer } from "mobx-react-lite";
import DetailsView from "./DetailsView";
import { SingleItem } from "api-client";

export default observer(function MEObjectDetail({
  open,
  close,
  object,
}: {
  open: boolean;
  close: () => void;
  object?: SingleItem;
}) {
  return <DetailsView open={open} close={close} object={object} />;
});

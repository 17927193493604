import {
  CertificationOut,
  EPDConformityOut,
  ProductSearchInput,
  SimpleMaterialOut,
} from "api-client";
import React, { useState } from "react";
import OtherPropertiesFilter from "./OtherPropertiesFilter";
import DINCategoryFilter from "./DINCategoryFilter";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import { DropdownOption } from "components/DropdownCheckbox";
import { observer } from "mobx-react-lite";
import ConformityFilter from "./ConformityFilter";
import MaterialFilter from "./MaterialFilter";
import OKBCategoryFilter, { OKBCategoryType } from "./OKBCategoryFilter";
import clsx from "clsx";
import { searchStore } from "store/Mapping/SearchStore";
import CertificationFilter from "./CertificationFilter";
import { FiltersOptions } from "store/Mapping/types";

interface RemoveFilters {
  OKB_categories?: OKBCategoryType;
  DIN_categories?: DropdownOption;
  other_properties?: DropdownOption;
  materials?: SimpleMaterialOut;
  conformity?: EPDConformityOut;
  certifications?: CertificationOut;
}

const ProductSearchFilters = observer(
  ({
    query,
    filters,
    setFilters,
  }: {
    query: string;
    filters: FiltersOptions;
    setFilters: (name: string, value: unknown[]) => void;
  }) => {
    const { t, i18n } = useTranslation();
    const isEng = i18n.language === "en";
    const [removingItem, setRemovingItem] = useState<RemoveFilters>();
    const [firstSearchRun, setFirstSearchRun] = React.useState(false);

    function resetFilters() {
      Object.keys(filters).forEach((item) => setFilters(item, []));
    }

    React.useEffect(() => {
      resetFilters();
    }, []);

    React.useEffect(() => {
      async function onFiltersChanged() {
        searchStore.resetCompliantItemsInfo();
        searchStore.resetSearchResults();
        setFirstSearchRun(false);
        await search();
      }

      (async () => await onFiltersChanged())();
    }, [filters, searchStore.selectedTab, query]);

    React.useEffect(() => {
      const shouldGetFirstNonCompliantList =
        !firstSearchRun &&
        !searchStore.productSearchLoading &&
        !searchStore.compliantItemsInfo.hasMore &&
        searchStore.compliantItemsInfo.nonCompliant === 0;

      shouldGetFirstNonCompliantList &&
        (async () => await getFirstNonCompliantList())();
    }, [
      searchStore.compliantItemsInfo.hasMore,
      searchStore.productSearchLoading,
    ]);

    const getFirstNonCompliantList = async () => {
      searchStore.setCurrentSearchPage(1);
      await search();
      setFirstSearchRun(true);
    };

    const filterExist = (key: string) =>
      Object.keys(filters).find((item) => item === key);

    function addSelected(name: string, value: never[]) {
      setFilters(name, value);
    }

    function removeItem(name: string, value?: never) {
      setRemovingItem((state) => ({
        ...state,
        [name]: value,
      }));
    }

    function manageDINFilters() {
      /* Only filter parents if all children are checked */
      const childrenIDs = filters.DIN_categories?.map((rec) => rec.children)
        .flat()
        .map((rec) => rec?.id);
      const filteredItems = filters.DIN_categories?.filter(
        (rec) => !childrenIDs?.includes(rec.id)
      );
      return { ...filters, DIN_categories: filteredItems } as FiltersOptions;
    }

    const search = async () => {
      if (searchStore.productSearchLoading) return;
      searchStore.setProductSearchLoading(true);
      const searchInput: ProductSearchInput = {
        query: query,
      };
      if (filters.DIN_categories?.length) {
        searchInput["din_category"] = filters.DIN_categories?.map((item) =>
          String(item?.value)
        );
      }
      if (filters.conformity?.length) {
        searchInput["epd_conformity"] = filters.conformity?.map(
          (item) => item.name
        );
      }
      if (filters.certifications?.length) {
        searchInput["certifications"] = filters.certifications?.map(
          (item) => item.name
        );
      }
      if (filters.materials?.length) {
        searchInput["material_name"] = filters.materials?.map(
          (item) => item.name ?? ""
        );
      }
      const isExternal = filters.other_properties?.find(
        (rec) => rec.id === "is_external"
      );
      const isLoadbearing = filters.other_properties?.find(
        (rec) => rec.id === "is_load_bearing"
      );

      if (isExternal) {
        searchInput["is_external"] = true;
      }
      if (isLoadbearing) {
        searchInput["is_load_bearing"] = true;
      }

      searchStore.setSearchInput(searchInput);
      await searchStore.getProducts();
      searchStore.setProductSearchLoading(false);
    };

    function filtersSelectors() {
      return (
        <div className="sm:flex flex-wrap relative mb-2">
          {filterExist("OKB_categories") ? (
            <OKBCategoryFilter
              selected={filters?.OKB_categories}
              setSelected={(value) =>
                addSelected("OKB_categories", value as never)
              }
              removingItem={removingItem?.OKB_categories}
              handleRemoveItem={() => removeItem("OKB_categories")}
            />
          ) : null}
          {filterExist("DIN_categories") ? (
            <DINCategoryFilter
              selected={filters?.DIN_categories}
              setSelected={(value) =>
                addSelected("DIN_categories", value as never)
              }
              removingItem={removingItem?.DIN_categories}
              handleRemoveItem={() => removeItem("DIN_categories")}
            />
          ) : null}
          {filterExist("materials") ? (
            <MaterialFilter
              selected={filters.materials}
              setSelected={(value) => addSelected("materials", value as never)}
              removingItem={removingItem?.materials}
              handleRemoveItem={() => removeItem("materials")}
            />
          ) : null}
          {filterExist("conformity") ? (
            <ConformityFilter
              selected={filters.conformity}
              setSelected={(value) => addSelected("conformity", value as never)}
              removingItem={removingItem?.conformity}
              handleRemoveItem={() => removeItem("conformity")}
            />
          ) : null}
          {filterExist("certifications") ? (
            <CertificationFilter
              selected={filters.certifications}
              setSelected={(value) =>
                addSelected("certifications", value as never)
              }
              removingItem={removingItem?.certifications}
              handleRemoveItem={() => removeItem("certifications")}
            />
          ) : null}
          {filterExist("other_properties") &&
          searchStore.selectedTab.type === "PRODUCT" ? (
            <OtherPropertiesFilter
              selected={filters.other_properties}
              setSelected={(value) =>
                addSelected("other_properties", value as never)
              }
              removingItem={removingItem?.other_properties}
              handleRemoveItem={() => removeItem("other_properties")}
            />
          ) : null}
        </div>
      );
    }

    const showClear = Object.values(filters).find((item) => item.length);

    function selectedFiltersContent() {
      const currentFilters = manageDINFilters();
      return (
        <div className="flex flex-wrap md:max-w-[650px] max-w-xs mt-2 truncate mb-2">
          {Object.entries(currentFilters).map((filter) =>
            filter[1].map(
              (
                item: { name: string | number; name_de?: string | number },
                index: number
              ) => (
                <Badge
                  key={index}
                  type="auto"
                  size="large"
                  className={clsx(
                    "bg-indigo-100 text-indigo-700 rounded-full mr-2 py-2 px-4 mb-2"
                  )}
                  onClose={() => removeItem(filter[0], item as never)}
                  closable
                >
                  <span className="truncate">
                    {isEng ? item.name : item?.name_de ?? item.name}
                  </span>
                </Badge>
              )
            )
          )}
        </div>
      );
    }

    return (
      <>
        <div className="border-b border-gray-200 flex items-center justify-center mt-6 mb-5">
          <span className="absolute bg-white px-3 text-sm font-normal text-gray-600">
            {t("mapping.filter")}
          </span>
        </div>

        <div className="flex items-center">
          {filtersSelectors()}
          {showClear && (
            <div
              onClick={resetFilters}
              className="ml-8 text-xs text-gray-600 font-medium underline cursor-pointer"
            >
              {t("commons.resetFilters")}
            </div>
          )}
        </div>
        {selectedFiltersContent()}
      </>
    );
  }
);

export default React.memo(ProductSearchFilters);

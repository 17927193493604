import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconGripVertical } from "@tabler/icons-react";
import clsx from "clsx";
import { GroupingRemoveIcon } from "../../utils";

interface GroupDraggbleItem {
  id: string;
  name: string;
  onRemove: () => void;
}

const GroupDraggbleItem: React.FC<GroupDraggbleItem> = ({
  id,
  name,
  onRemove,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : "auto",
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      className={clsx(
        "flex group items-center px-4 py-2 gap-2 justify-between bg-white",
        "hover:bg-indigo-50 hover:text-indigo-700"
      )}
    >
      <div className="flex items-center whitespace-nowrap">
        <IconGripVertical
          size={20}
          className="mr-2 min-w-[20px] cursor-grab focus:outline-none"
          {...listeners}
          {...attributes}
        />
        <span>{name}</span>
      </div>
      <div
        className={clsx(
          "cursor-pointer ml-1 min-w-[20px] invisible",
          "group-hover:visible"
        )}
        onClick={onRemove}
      >
        <GroupingRemoveIcon />
      </div>
    </li>
  );
};

export default GroupDraggbleItem;

import React from "react";
import TextInput from "components/TextInput";
import { getCurrentReferenceValue } from "features/MappingTools/utils";
import { useTranslation } from "react-i18next";
import { componentsStore } from "store/Mapping/ComponentsStore";
import { observer } from "mobx-react-lite";
import Input from "components/Input";
import ComponentChildContent from "../../ComponentChildOverview/ComponentChildContent";
import { ComponentDetailsByFileOut } from "api-client";

const AddedChild = ({
  childItem,
  rowIndex,
}: {
  childItem: ComponentDetailsByFileOut;
  rowIndex: number;
}) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const itemReferenceUnit = getCurrentReferenceValue(childItem.reference_unit);

  const onChangeValue = (
    key: keyof ComponentDetailsByFileOut,
    value?: string
  ) => {
    componentsStore.onChangeChildParams(key, rowIndex, value);
  };

  return (
    <div className="w-full bg-white border border-gray-300 rounded-md overflow-hidden mb-3">
      <ComponentChildContent childItem={childItem} removable className="px-6" />
      <div className="bg-gray-50 border-t border-gray-200 px-6 py-3 border-r flex justify-between gap-4 min-w-[200px]">
        <TextInput
          unit={`${
            isEng
              ? itemReferenceUnit?.name ?? "-"
              : itemReferenceUnit?.name_de ?? "-"
          }/${componentsStore.getValueFromObject("reference_unit", isEng)}`}
          noMargin
          labelName={`${t("components.unitConversion")}*`}
          handleChange={(value) => onChangeValue("unit_conversion", value)}
          value={childItem.unit_conversion as unknown as string}
          type="number"
          min={0}
          hasError={
            Number(childItem.unit_conversion) === 0 ||
            childItem.unit_conversion === undefined
          }
        />
        <Input
          placeholder={t("components.addComment")}
          labelName={t("components.comment")}
          noMargin
          containerClassName="w-full"
          onChange={(value) => onChangeValue("comment", value)}
          value={childItem.comment ?? ""}
        />
      </div>
    </div>
  );
};

export default observer(AddedChild);

import { MinusCircleIcon } from "@heroicons/react/outline";
import { BlueprintOUT, ImageOUT, imagesApi } from "api-client";
import { db } from "api-client-local/db";
import { getLocalAuthHeader } from "api-client-local/utils";
import React from "react";
import ImageResizer from "../ImageResizer";
import { useTranslation } from "react-i18next";

interface Props {
  remoteImages: ImageOUT[];
  setRemoteImages: React.Dispatch<React.SetStateAction<ImageOUT[]>>;
  blueprint: BlueprintOUT;
}

export const RemoteImages = ({
  remoteImages,
  setRemoteImages,
  blueprint,
}: Props) => {
  const { t } = useTranslation();
  const [images, setImages] = React.useState<ImageOUT[]>([]);

  React.useEffect(() => {
    const sorted = [...remoteImages].sort((a, b) => b.order - a.order);
    setImages(sorted);
  }, [remoteImages]);

  const onDeleteRemoteImage = (imageId: string) => {
    getLocalAuthHeader().then((authHeader) => {
      imagesApi
        .caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId, authHeader)
        .then(() => {
          db.deletedImages.add({ id: imageId }).then(() => {
            setRemoteImages(remoteImages.filter((img) => img.id !== imageId));
          });
        });
    });
  };

  return (
    <>
      {images.length > 0 && (
        <>
          <p className="font-semibold text-sm mt-2">
            {t("audits.uploadedImagesLabel")}
          </p>
          <div>
            {images.map((img: ImageOUT, index: number) => {
              return (
                <div
                  className={`bg-gray-100 border ${
                    index === 0 ? "border-dotted px-1" : ""
                  } max-w-[130px] my-3 inline-block mr-3 relative rounded-md`}
                  key={img.id}
                >
                  <ImageResizer
                    width="500"
                    height="500"
                    className="object-fill !w-full !h-full rounded-sm"
                    src={img.image ?? ""}
                    alt={img.id}
                    isLink
                  />
                  {index === 0 && (
                    <div className="text-[10px] text-gray-400 flex justify-center p-1">
                      {t("audits.previewImage")}
                    </div>
                  )}
                  <button
                    className="text-white absolute w-5 h-5 -top-2 !z-100 -right-2 bg-red-700 rounded-full"
                    onClick={() => {
                      onDeleteRemoteImage(img.id);
                    }}
                  >
                    <MinusCircleIcon />
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { componentsStore, Step } from "store/Mapping/ComponentsStore";
import Tooltip from "components/Tooltip";

export default observer(function OverviewTile({ step }: { step: Step }) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const checkValue = (value: string | []) => {
    if (String(value) === "true") {
      return t("components.yes");
    } else if (String(value) === "false") {
      return t("components.no");
    } else {
      return value;
    }
  };

  return (
    <div className="flex text-gray-600 flex-wrap">
      {step.fields.map((key, index) =>
        componentsStore.getValueFromObject(key, isEng) ? (
          <div className="mr-5" key={index}>
            <div className="flex items-center">
              <label className="text-sm">{t(`components.${key}`)}</label>
              {["preuse_qualification", "reusability_qualification"].includes(
                key
              ) ? (
                <Tooltip
                  content={t("components.tooltipStep3")}
                  className="ml-1"
                />
              ) : null}
              {key === "component_din_category" ? (
                <Tooltip
                  content={t("components.tooltipDinCategory")}
                  className="ml-1"
                />
              ) : null}
            </div>
            <div className="text-sm font-normal pt-1 text-gray-500 pb-4">
              {checkValue(componentsStore.getValueFromObject(key))}
            </div>
          </div>
        ) : null
      )}
    </div>
  );
});

import {
  IFCFileApi,
  OrganisationTypesApi,
  OrganisationsApi,
  BuildingsApi,
  UserBuildingsApi,
  BuildingInviteesApi,
  OrganisationAccessRequestsApi,
  UserOrganisationsApi,
  OrganisationDomainsApi,
  LocationsApi,
  UserApi,
  RepositoriesApi,
  AuditsApi,
  ImagesApi,
  InventoryMappingEditorApi,
  InventoryFileApi,
  CategoriesApi,
  DGNBApi,
  CommonURLsForApplicationsApi,
  SummaryApi,
  PowerBIApi,
  SearchApi,
  InventoryEPDsApi,
  ReportBuildingsApi,
  APIKeyApi,
  InventoryProductsApi,
  DatasetCheckerApi,
  OnlineApi,
  OfflineApi,
  ProductEditorApi,
  DetailReportTileApi,
  ComponentsApi,
  OperationalEnergyApi,
  MappingEditorObjectsApi,
  MappingEditorDynamicColumnsApi,
  DynamicMappingEditorApi,
  FileOperationsApi,
  ObjectOperationsApi,
  DashboardApi,
  CalculationsApi,
  RetoolWorkflowsApi
} from "./generated/index";

export * from "./generated/index";

export const BASE_PATH =
  localStorage.getItem("api") || import.meta.env.VITE_API_BASE_URL;

export const userApi = new UserApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const repositoriesApi = new RepositoriesApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const imagesApi = new ImagesApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const auditsApi = new AuditsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const ifcApi = new IFCFileApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const organisationsTypesApi = new OrganisationTypesApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const organisationsApi = new OrganisationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const buildingsApi = new BuildingsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const buildinginviteesApi = new BuildingInviteesApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const userBuildingApi = new UserBuildingsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const organisationAccessRequestApi = new OrganisationAccessRequestsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const userOrganisationsApi = new UserOrganisationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const organisationDomainsApi = new OrganisationDomainsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const locationsApi = new LocationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const inventoryMappingEditorAPI = new InventoryMappingEditorApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const inventoryFileAPI = new InventoryFileApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const categoriesAPI = new CategoriesApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const dgnbApi = new DGNBApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const commonApi = new CommonURLsForApplicationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const summaryAPI = new SummaryApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const powerBIAPI = new PowerBIApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const searchAPI = new SearchApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const inventoryEPDsApi = new InventoryEPDsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const reportBuildingApi = new ReportBuildingsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const apiKey = new APIKeyApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const inventoryProductsApi = new InventoryProductsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const datasetCheckerApi = new DatasetCheckerApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const onlineAPi = new OnlineApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const offlineApii = new OfflineApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const productEditorApi = new ProductEditorApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const detailReportTileApi = new DetailReportTileApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const componentsApi = new ComponentsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const operationalEnergyApi = new OperationalEnergyApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const mappingEditorDynamicColumns = new MappingEditorDynamicColumnsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const mappingEditorObjectsApi = new MappingEditorObjectsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const dynamicMappingEditorApi = new DynamicMappingEditorApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const fileOperationsApi = new FileOperationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const objectOperationsApi = new ObjectOperationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const buildingDashboardApi = new DashboardApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
});

export const calculationsApi = new CalculationsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

export const retoolWorkflowsApi = new RetoolWorkflowsApi({
  basePath: BASE_PATH,
  isJsonMime: () => true,
})

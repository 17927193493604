import { useSortable } from "@dnd-kit/sortable";
import { PropertyBrowserTreeNode } from "api-client";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { IconEyeOff, IconGripVertical } from "@tabler/icons-react";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { useTranslation } from "react-i18next";

const DraggbleItem = (props: {
  row: PropertyBrowserTreeNode;
  isGroupingColumn: boolean;
  selectedItemsLength: number;
}) => {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.row.id });

  const isInfoColumnAndOneItem =
    Number(props.selectedItemsLength) === 1 && !props.isGroupingColumn;

  const removeCurrentRow = (id: string) => {
    if (isInfoColumnAndOneItem) return;
    if (props.isGroupingColumn) {
      dynamicMEStore.removeGroupingRule(id);
    } else {
      const updatedList = dynamicMEStore.visibleColumnProperties.filter(
        (item) => item.id !== id
      );
      dynamicMEStore.setVisibleColumnProperties(updatedList);
    }
    dynamicMEStore.removeItemFromSortList(id);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      className={clsx(
        "flex group items-center px-4 py-2 gap-2 justify-between text-gray-700",
        "hover:bg-indigo-50 hover:text-indigo-700",
        {
          "opacity-70": isInfoColumnAndOneItem,
        }
      )}
    >
      <div className="flex items-center whitespace-nowrap">
        {props.selectedItemsLength > 1 && (
          <IconGripVertical
            {...attributes}
            {...listeners}
            size={20}
            className="mr-2 min-w-[20px] cursor-grab focus:outline-none"
          />
        )}
        <span>{isEng ? props.row.name : props.row.name_de}</span>
      </div>
      <IconEyeOff
        className={clsx(
          "cursor-pointer ml-1 min-w-[20px] invisible",
          isInfoColumnAndOneItem ? "hidden" : "group-hover:visible"
        )}
        size={20}
        onClick={() => removeCurrentRow(props.row.id)}
      />
    </li>
  );
};

export default DraggbleItem;

import React from "react";
import Input from "components/Input";
import { observer } from "mobx-react-lite";
import Checkbox from "components/Checkbox";
import TagsInput from "components/TagsInput";
import TextInput from "components/TextInput";
import InputLabel from "components/InputLabel";
import { useTranslation } from "react-i18next";
import SimpleSelect from "components/SimpleSelect";
import {
  buildingProductStore,
  Option,
} from "store/Mapping/BuildingProductStore";
import { BNB_DOCUMENT } from "utils";

export default observer(function GeneralInformationStep() {
  const { t } = useTranslation();

  const addTag = (value: string) =>
    buildingProductStore.onChangeData("auto_mapping_tags", [
      ...buildingProductStore.data.auto_mapping_tags,
      {
        id: String(buildingProductStore.data.auto_mapping_tags.length),
        name: value,
      },
    ]);

  const removeTag = (value: string) =>
    buildingProductStore.onChangeData(
      "auto_mapping_tags",
      buildingProductStore.data.auto_mapping_tags.filter(
        (item) => item.id !== value
      )
    );

  buildingProductStore.checkStepValidity(0);

  return (
    <>
      <div className="text-lg font-semibold mb-6">
        {t("productEditor.generalInformation")}
      </div>
      <div className="bg-white border-gray-300 border p-6 rounded-md">
        <Input
          name="name"
          labelName={`${t("productEditor.name")}*`}
          placeholder={t("productEditor.enterName")}
          onChange={(value: string | undefined, name?: string) =>
            buildingProductStore.onChangeData(name as string, value)
          }
          value={buildingProductStore.data.name}
        />
        <SimpleSelect
          options={buildingProductStore.optionsLists.din_category as Option[]}
          placeholder={t("productEditor.chooseCategory")}
          labelName={t("productEditor.din_category")}
          name="din_category"
          required
          onChange={(value, name) =>
            buildingProductStore.onChangeData(name as string, value)
          }
          current={buildingProductStore.data.din_category}
          displayKey="name"
        />
        <TextInput
          unit={t("productEditor.years")}
          labelName={`${t("productEditor.life_span")}*`}
          placeholder={t("productEditor.enterYears")}
          type="number"
          min={1}
          name="life_span"
          handleChange={(value: string | undefined, name: string | undefined) =>
            buildingProductStore.onChangeData(name as string, value)
          }
          value={buildingProductStore.data.life_span}
          containerClass="!mb-1"
        />
        <p
          className="text-gray-500 mb-2 text-sm"
          dangerouslySetInnerHTML={{
            __html: t("productEditor.life_span_subtitle", {
              link: BNB_DOCUMENT,
            }),
          }}
        ></p>
        <Checkbox
          labelElement={t("productEditor.is_external")}
          onChange={(e) =>
            buildingProductStore.onChangeData(
              "is_external",
              e.currentTarget.checked
            )
          }
          checked={buildingProductStore.data.is_external}
        />
        <Checkbox
          labelElement={t("productEditor.is_load_bearing")}
          onChange={(e) =>
            buildingProductStore.onChangeData(
              "is_load_bearing",
              e.currentTarget.checked
            )
          }
          checked={buildingProductStore.data.is_load_bearing}
        />
        <div className="mt-3">
          <InputLabel
            labelName={t("productEditor.auto_mapping_tags")}
            name={"special_features"}
          />
          <TagsInput
            items={buildingProductStore.data.auto_mapping_tags}
            addItem={addTag}
            removeItem={removeTag}
            placeholder={t("productEditor.addTags")}
          />
          <div className="text-gray-500 text-sm mt-1.5">
            {t("productEditor.autoMappingHint")}
          </div>
        </div>
      </div>
    </>
  );
});

import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { searchStore } from "store/Mapping/SearchStore";

export default observer(function FooterRowCount() {
  const { t } = useTranslation();

  if (!searchStore.searchResults.count) return <></>;
  return (
    <div className="flex">
      <div className="pt-2 text-gray-700 text-xs">
        {t("mapping.footerRowCount", {
          count: searchStore.searchResults.items.length,
          total: searchStore.searchResults.count,
        })}
      </div>
      <div className="pt-2 text-gray-500 text-xs ml-1">
        {t("mapping.footerRowCount2", {
          compliant: searchStore.compliantItemsInfo.compliant,
          nonCompliant: searchStore.compliantItemsInfo.nonCompliant,
        })}
      </div>
    </div>
  );
});

import React from "react";
import { dynamicMEStore } from "store/Mapping/DMEStore";

const useKeyPressed = () => {
  const handleKeyDown = (key: string, type: string) => {
    if (key === "CtrlA") {
      onPressedCtrlA();
    } else if (key === "ShiftDown") {
      onPressedShiftDown();
      dynamicMEStore.setLastShiftSelectKey("ShiftDown");
    } else if (key === "ShiftUp") {
      onPressedShiftUp();
      dynamicMEStore.setLastShiftSelectKey("ShiftUp");
    } else {
      type === "keydown"
        ? dynamicMEStore.setKeyPressed(key)
        : dynamicMEStore.setKeyPressed(null);
    }
    if (type === "keydown" && !["ShiftDown", "ShiftUp"].includes(key)) {
      dynamicMEStore.setLastShiftSelectKey(undefined);
    }
  };

  const onPressedShift = (row_index: number) => {
    let arrayRange: string[];
    if (row_index > Number(dynamicMEStore.selectedStartIndex)) {
      arrayRange = Array.from(
        {
          length:
            Number(row_index) - Number(dynamicMEStore.selectedStartIndex) + 1,
        },
        (_, i) => String(i + Number(dynamicMEStore.selectedStartIndex))
      );
    } else {
      arrayRange = Array.from(
        {
          length:
            Number(dynamicMEStore.selectedStartIndex) - Number(row_index) + 1,
        },
        (_, i) => String(i + Number(row_index))
      );
    }
    const timeout = setTimeout(() => {
      dynamicMEStore.addToSelectedRows(arrayRange);
      clearTimeout(timeout);
    }, 200);
  };

  const onPressedCtrlA = () => {
    dynamicMEStore.setSelectAllChecked(true);
  };

  const onPressedShiftDown = () => {
    if (dynamicMEStore.selectedStartIndex === null) return;
    //  Checking last pressed key to reverse last checked item on change direction
    const offset = dynamicMEStore.lastShiftSelectKey === "ShiftUp" ? 0 : 1;
    let nextIndex = Number(dynamicMEStore.selectedStartIndex) + offset;

    if (dynamicMEStore.selectedRowIndexes.includes(String(nextIndex))) {
      dynamicMEStore.removeFromSelectedRows(String(nextIndex));
      dynamicMEStore.setSelectedStartIndex(nextIndex);
    } else if (nextIndex) {
      dynamicMEStore.addToSelectedRows([String(nextIndex)]);
      dynamicMEStore.setSelectedStartIndex(nextIndex);
    }
  };

  const onPressedShiftUp = () => {
    if (dynamicMEStore.selectedStartIndex === null) return;
    const offset = dynamicMEStore.lastShiftSelectKey === "ShiftDown" ? 0 : 1;
    let prevIndex = Number(dynamicMEStore.selectedStartIndex) - offset;

    if (dynamicMEStore.selectedRowIndexes.includes(String(prevIndex))) {
      dynamicMEStore.removeFromSelectedRows(String(prevIndex));
      dynamicMEStore.setSelectedStartIndex(prevIndex);
    } else if (prevIndex > -1) {
      dynamicMEStore.addToSelectedRows([String(prevIndex)]);
      dynamicMEStore.setSelectedStartIndex(prevIndex);
    }
  };

  return {
    onPressedShift,
    handleKeyDown,
  };
};

export default useKeyPressed;

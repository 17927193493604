import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import clsx from "clsx";

const MappingEditorFooter = () => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "text-sm font-medium bg-gray-50 text-gray-500 p-2",
        "border-t border-gray-300 w-full z-2"
      )}
    >
      {t(
        dynamicMEStore.hasGrouping()
          ? "mappingEditor.groupesLoadedStatus"
          : "mappingEditor.objectsLoadedStatus",
        {
          objects: dynamicMEStore.data.items.length,
          total: dynamicMEStore.data.count,
        }
      )}
    </div>
  );
};

export default observer(MappingEditorFooter);

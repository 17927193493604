import React, { useState } from "react";
import {
  ComponentDetailsByFileOut,
  EPDGroupOUT,
  ProductSearchOutput,
} from "api-client";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { LinkIcon } from "@heroicons/react/outline";
import Collapse from "components/Collapse";
import clsx from "clsx";
import DatasetView, { EPDGroup } from "../DatasetView";
import { componentsStore } from "store/Mapping/ComponentsStore";
import ProductComponentInfo from "../ProductComponentInfo";
import { IconInfoCircle } from "@tabler/icons-react";
import { searchStore } from "store/Mapping/SearchStore";
import ProductDetailsPopup from "../ProductDetailsPopup";

interface ProductRowProps {
  product: ProductSearchOutput;
  match: (product_id: string) => Promise<void>;
  testID?: string;
  settings?: React.ReactNode;
  mappingProgress?: boolean;
  onMappingProgressChange?: (isInProgress: boolean) => void;
}

export default function ProductRow({
  product,
  match,
  testID,
  settings,
  mappingProgress = false,
  onMappingProgressChange,
}: ProductRowProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [openDetailsPopup, setOpenDetailsPopup] = React.useState(false);

  const handleMapEpd = React.useCallback(async () => {
    if (product?.id) {
      setLoading(true);
      onMappingProgressChange?.(true);
      await match(product.id);
      setLoading(false);
      onMappingProgressChange?.(false);
    }
  }, []);

  const handleAdd = () => {
    componentsStore.addChildItem(product as ComponentDetailsByFileOut);
    componentsStore.setOpenedSearchInCE(false);
  };

  function createEpdsList(group: EPDGroupOUT) {
    return group.epds?.map((item) => {
      return {
        factor: item?.factor,
        epd_name: item.epd?.name_de ?? "-",
        material_name: item.material?.name ?? "-",
        dataset_source: item.epd?.dataset_source ?? "-",
        dataset_url: item.epd?.url ?? "-",
        recyclability_score: item.material?.recyclability_score ?? "-",
        recyclability_qualification:
          item.material?.recyclability_qualification ?? "-",
        reference_unit: item.epd?.reference_unit ?? "-",
        reference_size: item.epd?.reference_size || "-",
        conformity: group?.conformity ?? "-",
        mass_value_m: item.epd?.mass_value_m ?? "-",
        mass_value_m2: item.epd?.mass_value_m2 ?? "-",
        mass_value_m3: item.epd?.mass_value_m3 ?? "-",
      };
    });
  }

  const epdGroups = product?.groups?.map((group) => {
    const epdsList = createEpdsList(group);
    return {
      epds: epdsList,
      conformity: group.conformity,
      certifications: group.certifications,
    };
  });

  return (
    <div className="border border-gray-200 rounded-md mb-3 last-of-type:mb-0 shadow-sm">
      <div className="p-3 text-gray-500 border-b border-gray-100 last-of-type:border-b-0 md:flex items-center justify-between">
        <ProductComponentInfo item={product} />
        <div className="flex justify-end items-center mt-2 md:mt-0 whitespace-nowrap">
          {componentsStore.openedSearchInCE ? (
            <Button
              className={"cursor-pointer"}
              width="fit-content"
              type="secondary"
              onClick={handleAdd}
              leadingIcon={<LinkIcon />}
              testID={"addItem_Button"}
            >
              {t("mapping.addProduct", {
                name:
                  searchStore.selectedTab.type === "PRODUCT"
                    ? t("mapping.product")
                    : t("mapping.component"),
              })}
            </Button>
          ) : (
            <Button
              className={`${
                mappingProgress ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              width="fit-content"
              type="secondary"
              onClick={handleMapEpd}
              leadingIcon={<LinkIcon />}
              loading={loading}
              disable={mappingProgress}
              testID={testID}
            >
              {t("mapping.linkProduct", {
                name:
                  searchStore.selectedTab.type === "PRODUCT"
                    ? t("mapping.product")
                    : t("mapping.component"),
              })}
            </Button>
          )}
          <IconInfoCircle
            className="text-gray-500 ml-2 cursor-pointer"
            onClick={() => setOpenDetailsPopup(true)}
          />
          {settings ? <div>{settings}</div> : null}
        </div>
      </div>
      {product.type === "PRODUCT" &&
        product.groups &&
        product.groups?.length > 0 && (
          <Collapse
            headerTitle={t("mapping.hideDataset")}
            closeTitle={t("mapping.showDataset")}
            defaultClose
            headerClassName={clsx(
              "!bg-white text-indigo-600 hover:text-indigo-700 text-sm font-medium px-0 border-b-0 rounded-b-md"
            )}
          >
            <div className="px-4 pb-4">
              {epdGroups?.length
                ? epdGroups.map((item, index) => (
                    <DatasetView
                      key={index}
                      epdGroup={item as EPDGroup}
                      referenceUnit={product.reference_unit}
                    />
                  ))
                : null}
            </div>
          </Collapse>
        )}
      <ProductDetailsPopup
        productId={product.id}
        productName={product.name}
        productType={product.type}
        open={openDetailsPopup}
        close={() => setOpenDetailsPopup(false)}
      ></ProductDetailsPopup>
    </div>
  );
}

import DropdownCheckbox from "components/DropdownCheckbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  buildingProductStore,
  Option,
} from "store/Mapping/BuildingProductStore";

export default observer(function DataSourceFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
  disabled,
}: {
  selected?: Option[];
  setSelected: React.Dispatch<React.SetStateAction<Option[]>>;
  removingItem: Option | undefined;
  handleRemoveItem: () => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  React.useEffect(() => {
    (async () => {
      await buildingProductStore.fetchEPDDatasetSources();
    })();
  }, []);

  const onChangeCategories = (value: Option[]) => setSelected(value);

  if (!buildingProductStore.filtersOptions.epd_data_sources) return <></>;
  return (
    <DropdownCheckbox<Option>
      displayKey={"name"}
      required={true}
      handleSelect={onChangeCategories}
      labelName={t("mapping.dataSource")}
      name="epd_data_sources"
      items={buildingProductStore.filtersOptions.epd_data_sources}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px] !mb-3"
      optionsClassName="z-40 !max-w-fit"
      checkedItems={selected}
      disabled={disabled}
    />
  );
});

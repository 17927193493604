import React from "react";
import { useTranslation } from "react-i18next";
import { IconEye, IconPlus, IconTrash } from "@tabler/icons-react";
import clsx from "clsx";
import { EMPTY_SORT_ROW } from "../../const";
import { PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { observer } from "mobx-react-lite";
import { SortProps } from ".";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import LinearLoading from "components/LinearLoading";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import SortDraggbleItem from "./SortDraggbleItem";

const wrapperID = "sort-wrapper";

function SortMenu({
  cursorPosition,
  items,
  onChangeProperty,
}: {
  cursorPosition: { x: number; y: number };
  items: PropertyBrowserTreeNode[];
  onChangeProperty: (value: PropertyBrowserTreeNode, index: number) => void;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollPos, setScrollPos] = React.useState<number>(0);

  const onChangeSortOption = (value: string | undefined, index: number) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows[index].sort = String(value);
    dynamicMEStore.setSelectedSortedByItems([...rows]);
  };

  const addRow = () => {
    const rows = [
      ...dynamicMEStore.selectedSortedByItems,
      {
        ...EMPTY_SORT_ROW,
        id: String(dynamicMEStore.selectedSortedByItems.length),
      },
    ];
    dynamicMEStore.setSelectedSortedByItems(rows);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeCurrentRow = (index: number) => {
    if (dynamicMEStore.selectedSortedByItems.length === 1) {
      dynamicMEStore.removeAllSortingRules();
    } else {
      const rows = [...dynamicMEStore.selectedSortedByItems];
      rows.splice(index, 1);
      dynamicMEStore.setSelectedSortedByItems([...rows]);
    }
  };

  const handleOnDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = dynamicMEStore.selectedSortedByItems.findIndex(
        (item) => item.id === active.id
      );
      const newIndex = dynamicMEStore.selectedSortedByItems.findIndex(
        (item) => item.id === over.id
      );

      const reorderedItems = arrayMove(
        dynamicMEStore.selectedSortedByItems,
        oldIndex,
        newIndex
      );

      dynamicMEStore.setSelectedSortedByItems(
        reorderedItems as unknown as SortProps[]
      );
    }
  };

  const onScrollDiv = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const containerElement = e.target as HTMLDivElement;
    setScrollPos(containerElement.scrollTop);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const rows = dynamicMEStore.selectedSortedByItems;
  const hasAnyPropertySet =
    rows.map((e) => e.property).filter(Boolean).length > 0;

  return (
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
      }}
      onClick={(e) => e.stopPropagation()}
      id={wrapperID}
    >
      <div className="flex items-center px-4 py-1 border-b rounded-t-md border-gray-300 bg-gray-50 text-xs text-gray-500">
        <IconEye size={19} className="mr-2" stroke={2.5} />
        {`${t("mappingEditor.sorted")} ${t("mappingEditor.by")}`}
      </div>
      {dynamicMEStore.dataFetchLoading && (
        <LinearLoading type="auto" containerClassName="top-7 h-1" />
      )}
      <div
        className={clsx("pt-1.5 max-h-[325px]", {
          "overflow-y-auto": rows.length > 6,
        })}
        ref={scrollContainerRef}
        onScroll={onScrollDiv}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleOnDragEnd}
        >
          <SortableContext
            items={rows.map((row) => row.id)}
            strategy={verticalListSortingStrategy}
          >
            <ul style={{ listStyle: "none", padding: 0 }}>
              {rows.map((row, index) => (
                <SortDraggbleItem
                  key={row.id}
                  id={row.id}
                  index={index}
                  row={row}
                  items={items}
                  isEng={isEng}
                  onChangeProperty={onChangeProperty}
                  onChangeSortOption={onChangeSortOption}
                  removeCurrentRow={removeCurrentRow}
                  scrollPos={scrollPos}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </div>
      <div className="border-t border-gray-200 transition text-sm">
        <div
          className="flex px-4 py-2 items-center cursor-pointer text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group"
          onClick={addRow}
        >
          <IconPlus className="mr-2" size={20} />
          <span>{t("mappingEditor.addSortingProperty")}</span>
        </div>
        <div
          className={clsx(
            "flex px-4 py-2 items-center cursor-pointer",
            hasAnyPropertySet
              ? "text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group"
              : "pointer-events-none cursor-not-allowed text-gray-300"
          )}
          onClick={() => dynamicMEStore.removeAllSortingRules()}
        >
          <IconTrash className="mr-2" size={20} />
          <span>{t("mappingEditor.deleteSorting")}</span>
        </div>
      </div>
    </div>
  );
}

export default observer(SortMenu);

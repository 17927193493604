import { complianceChipsGroup } from "features/MappingTools/utils";
import React from "react";
import { buildingStore } from "store/Building/BuildingStore";
import { dynamicMEStore } from "store/Mapping/DMEStore";

export function useComplianceInfo() {
  React.useEffect(() => {
    (async () => {
      await dynamicMEStore.fetchCertificationData();
      await dynamicMEStore.fetchConformities();
    })();
  }, []);

  const getCurrentCertification = (certificationId?: string | null) => {
    const certification_id =
      certificationId ?? buildingStore.currentBuilding?.certification_id;
    return dynamicMEStore.certificationOptions.find(
      (item) => item.id === certification_id
    );
  };

  const getConformitiesOfCertification = (certificationId?: string | null) => {
    return getCurrentCertification(certificationId)?.conformities ?? [];
  };

  const getCurrentConformity = () => {
    return getConformitiesOfCertification().find(
      (item) => item.id === buildingStore.currentBuilding?.conformity_id
    )?.name;
  };

  function getCertificationChipsGroup(availableItems: string[] | undefined) {
    const filteredItems = dynamicMEStore.certificationOptions.filter(
      (item) => item.name !== "Keine"
    );

    return complianceChipsGroup(filteredItems, availableItems);
  }

  function getConformityChipsGroup(availableItems: string[] | undefined) {
    const filteredItems = dynamicMEStore.conformityOptions.filter(
      (item) => item.name !== ""
    );

    return complianceChipsGroup(filteredItems, availableItems);
  }

  return {
    getCurrentCertification,
    getConformitiesOfCertification,
    getCurrentConformity,
    getCertificationChipsGroup,
    getConformityChipsGroup,
  };
}

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/Mapping/SearchStore";
import { IconCube, IconDatabaseOff, IconSearchOff } from "@tabler/icons-react";
import clsx from "clsx";
import CustomComponentsEmptyState from "./CustomComponentsEmptyState";
import EmptyStateButtons from "./EmptyStateButtons";
import { componentsStore } from "store/Mapping/ComponentsStore";
import { SEARCH_TABS_LIST } from "features/MappingTools/consts";

const EmptyState = () => {
  const { t } = useTranslation();

  function getCurrentStateIcon() {
    if (searchStore.selectedTab.id === SEARCH_TABS_LIST[1].id) {
      return (
        <IconCube className="bg-teal-300 rounded-full p-2 w-16 h-16 mr-2 text-teal-900" />
      );
    }
  }

  function getCurrentStateDescription() {
    if (searchStore.selectedTab.type === "PRODUCT") {
      if (searchStore.hasAnyFilters()) {
        return `${t("mapping.emptyStateProductWithFilter")} ${
          !searchStore.selectedTab.source
            ? t("mapping.emptyStateProductWithFilter2")
            : ""
        }`;
      } else {
        return t("mapping.emptyStateProductWithoutFilter");
      }
    }
    if (
      searchStore.selectedTab.type === "COMPONENT" &&
      searchStore.hasAnyFilters()
    ) {
      if (
        searchStore.selectedTab.source !== "custom" &&
        !componentsStore.openedSearchInCE
      ) {
        return `${t("mapping.emptyStateComponentWithFilter")} ${t(
          "mapping.emptyStateComponentWithFilter2"
        )}. ${t("mapping.emptyStateComponentWithFilter3")}`;
      }
      if (componentsStore.openedSearchInCE) {
        return `${t("mapping.emptyStateComponentWithFilter")}.`;
      } else {
        return `${t("mapping.emptyStateComponentWithFilter")} ${t(
          "mapping.emptyStateComponentWithFilter2"
        )}.`;
      }
    } else {
      return "";
    }
  }

  const shouldShowOpenProductEditor = () =>
    searchStore.selectedTab.id === SEARCH_TABS_LIST[3].id &&
    !searchStore.hasAnyFilters();

  return (
    <div
      className={clsx(
        "flex flex-col w-full items-center justify-center",
        searchStore.selectedTab.source === "custom" ? "mt-20" : "mt-[155px]",
        shouldShowOpenProductEditor() &&
          !searchStore.searchError &&
          !componentsStore.openedSearchInCE
          ? "!mt-8"
          : ""
      )}
    >
      {searchStore.searchError ? (
        <Fragment>
          <IconDatabaseOff className="w-16 h-16 mr-2 text-gray-200" />
          <span className="font-normal text-sm text-gray-500 p-4 max-w-[800px] text-center">
            {t("mapping.serverErrorMsg", {
              item:
                searchStore.selectedTab.type === "PRODUCT"
                  ? t("mapping.products")
                  : t("mapping.components"),
            })}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          {searchStore.hasAnyFilters() ? (
            <IconSearchOff className="w-16 h-16 mr-2 text-gray-200" />
          ) : (
            getCurrentStateIcon()
          )}
          <span className="font-normal text-sm text-gray-500 p-4 max-w-[803px] text-center">
            {getCurrentStateDescription()}
          </span>
          {shouldShowOpenProductEditor() ? (
            <CustomComponentsEmptyState />
          ) : null}
          <EmptyStateButtons
            enableProductEditorCreation={shouldShowOpenProductEditor()}
          />
        </Fragment>
      )}
    </div>
  );
};

export default observer(EmptyState);

import { CPXValueOUT, MaterialFlowStatusEnum, OperationType } from "api-client";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { searchStore } from "store/Mapping/SearchStore";
import {
  IconHome,
  IconHomeMove,
  IconLinkOff,
  IconSearch,
  IconSparkles,
} from "@tabler/icons-react";
import useFeatureFlag from "hooks/useFeatureFlag";
import { buildingProductStore } from "store/Mapping/BuildingProductStore";
import useMappingEditor from "../useMappingEditor";

export interface MenuItem {
  id: string;
  title?: string;
  items?: MenuItem[];
  label?: string | null;
  icon?: React.ReactNode;
  tooltip?: string;
  children?: MenuItem[];
  value?: string | number | null;
  onClick?: () => void;
}

const ICON_MATERIAL_FLOW_ADDED = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M14.8333 9H16.5L9 1.5L1.5 9H3.16667V14.8333C3.16667 15.2754 3.34226 15.6993 3.65482 16.0118C3.96738 16.3244 4.39131 16.5 4.83333 16.5H9.41667M6.5 16.5V11.5C6.5 11.058 6.6756 10.6341 6.98816 10.3215C7.30072 10.0089 7.72464 9.83333 8.16667 9.83333H9.83333C10.2754 9.83333 10.6993 10.0089 11.0118 10.3215C11.3244 10.6341 11.5 11.058 11.5 11.5M17.3333 14.8333H12.3333M12.3333 14.8333L14.8333 17.3333M12.3333 14.8333L14.8333 12.3333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ICON_MATERIAL_FLOW_TEMP = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M15.4167 7.91667L10 2.5L2.5 10H4.16667V15.8333C4.16667 16.2754 4.34226 16.6993 4.65482 17.0118C4.96738 17.3244 5.39131 17.5 5.83333 17.5H7.5M7.5 17.5H9.58333M7.5 17.5V12.5C7.5 12.058 7.67559 11.634 7.98816 11.3215C8.30072 11.0089 8.72464 10.8333 9.16667 10.8333M15.4169 13.3333L17.8613 15.8333M17.8613 15.8333L15.4169 18.3333M17.8613 15.8333L14.1113 15.8333C13.463 15.8333 12.8413 15.5699 12.3828 15.1011C11.9244 14.6323 11.6669 13.9964 11.6669 13.3333C11.6669 12.6703 11.9244 12.0344 12.3828 11.5656C12.8413 11.0967 13.463 10.8333 14.1113 10.8333H17.0833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const useBuildActionBar = () => {
  const { ifc_id } = useParams();
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();
  const { mappingToObjects, mappingToFile } = useMappingEditor();

  const handleOpenProductSearch = () => {
    dynamicMEStore.setCurrentRow(undefined);
    dynamicMEStore.setMatchForSelectedItems(true);
    searchStore.setOpenProductSearch(true);
    dynamicMEStore.setOpenBulkActionsMenu(false);
  };

  const handleAutoMapp = async (mode: OperationType) => {
    if (dynamicMEStore.selectAllChecked) {
      const confirmedAndMapped = await mappingToFile(mode);
      if (confirmedAndMapped) {
        await dynamicMEStore.fetchObjectsList(0, true, ifc_id);
        dynamicMEStore.setSelectAllChecked(false);
        dynamicMEStore.setSelectedRows([]);
      }
    } else {
      await mappingToObjects(mode);
    }
    dynamicMEStore.setOpenBulkActionsMenu(false);
  };

  const handleObjectUpdateData = async (
    material_flow_status: MaterialFlowStatusEnum,
    cpx_preuse_id?: string
  ) => {
    if (dynamicMEStore.selectAllChecked) {
      await mappingToFile("update_objects", null, {
        material_flow_status: material_flow_status,
        cpx_preuse_id: cpx_preuse_id,
      });
    } else {
      await mappingToObjects("update_objects", null, null, {
        material_flow_status: material_flow_status,
        cpx_preuse_id: cpx_preuse_id,
      });
    }
    dynamicMEStore.setOpenBulkActionsMenu(false);
  };

  const EDIT_PRODUCT_MAPPING_ITEMS = {
    id: "1",
    title: t("mappingEditor.editProductMapping"),
    items: [
      {
        id: "1_1",
        label: t("mappingEditor.manualMapping"),
        icon: <IconSearch size={19} />,
        onClick: () => handleOpenProductSearch(),
      },
      {
        id: "1_2",
        label: t("mappingEditor.autoMapping"),
        icon: <IconSparkles size={19} />,
        onClick: () => handleAutoMapp("auto_map_match"),
      },
      {
        id: "1_3",
        label: t("mappingEditor.undoMapping"),
        icon: <IconLinkOff size={19} />,
        onClick: () => handleAutoMapp("undo_match"),
      },
    ],
  };

  const SET_AS_ADDED_SUB_MENU_ITEMS = [
    {
      id: "2_1_1",
      title: t("mappingEditor.defineMaterialOrigin"),
      items: [
        ...buildingProductStore.optionsLists.preuse_qualification
          .map((rec) => ({
            id: rec.id,
            label: rec.name,
            value: `Score ${Number((rec as CPXValueOUT).numeric_value) * 100}`,
            onClick: () =>
              handleObjectUpdateData(
                MaterialFlowStatusEnum.Hinzugefgt,
                String(rec.id)
              ),
          }))
          .reverse(),
        {
          id: "Later",
          label: t("mappingEditor.detemineLater"),
          tooltip: t("mappingEditor.determineLaterTooltip"),
          onClick: () =>
            handleObjectUpdateData(MaterialFlowStatusEnum.Hinzugefgt),
        },
      ] as MenuItem[],
    },
  ];

  const EDIT_MATERIAL_FLOW_STATUS_MENU = {
    id: "2",
    title: t("mappingEditor.editMaterialFlow"),
    items: [
      {
        id: MaterialFlowStatusEnum.Hinzugefgt,
        label: t("mappingEditor.setAsAdded"),
        icon: ICON_MATERIAL_FLOW_ADDED,
        tooltip: t("mappingEditor.addMenuTooltip"),
        children: SET_AS_ADDED_SUB_MENU_ITEMS,
      },
      {
        id: MaterialFlowStatusEnum.Verbleibt,
        label: t("mappingEditor.setAsRemains"),
        icon: <IconHome size={19} />,
        tooltip: t("mappingEditor.remainMenuTooltip"),
        onClick: () => handleObjectUpdateData(MaterialFlowStatusEnum.Verbleibt),
      },
      {
        id: MaterialFlowStatusEnum.Entfernt,
        label: t("mappingEditor.setAsRemoved"),
        icon: <IconHomeMove size={19} />,
        tooltip: t("mappingEditor.removeMenuTooltip"),
        onClick: () => handleObjectUpdateData(MaterialFlowStatusEnum.Entfernt),
      },
      {
        id: MaterialFlowStatusEnum.TemporrHinzugefgt,
        label: t("mappingEditor.setAsTemporaryAdded"),
        icon: ICON_MATERIAL_FLOW_TEMP,
        tooltip: t("mappingEditor.tempMenuTooltip"),
        onClick: () =>
          handleObjectUpdateData(MaterialFlowStatusEnum.TemporrHinzugefgt),
      },
    ],
  };

  const menuList: MenuItem[] = [
    EDIT_PRODUCT_MAPPING_ITEMS,
    ...(isFeatureVisible("material_flow_status")
      ? [EDIT_MATERIAL_FLOW_STATUS_MENU]
      : []),
  ];

  return {
    handleOpenProductSearch,
    handleAutoMapp,
    menuList,
  };
};

export default useBuildActionBar;

import React, { ReactNode } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import clsx from "clsx";

interface DebouncedInputProps {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  height?: "large" | "middle" | "small";
  className?: string;
  labelName?: string;
  containerClassName?: string;
  icon?: ReactNode;
  showIcon?: boolean;
}

const DebouncedInput = React.forwardRef(
  (
    {
      value: initialValue,
      onChange,
      debounce = 500,
      height = "large",
      className,
      labelName,
      containerClassName,
      icon,
      showIcon = true,
      ...props
    }: DebouncedInputProps &
      Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange?.(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [value]);

    return (
      <div
        className={clsx(
          `pb-15 ${containerClassName}`,
          props.hidden ? "hidden" : ""
        )}
      >
        {labelName ? (
          <label className="text-sm flex items-center text-gray-700 mb-0.5">
            {labelName}
          </label>
        ) : null}

        <div className="relative text-gray-400 focus-within:text-gray-600">
          <input
            ref={ref}
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={props.placeholder ?? ""}
            className={clsx(
              "block w-full bg-white border border-gray-300 rounded-md font-light p-2",
              "leading-5 text-gray-900 text-sm placeholder-gray-400",
              "focus:border-gray-300 sm:text-smw-36",
              {
                "h-large": height === "large",
                "h-middle": height === "middle",
                "h-small": height === "small",
              },
              className
            )}
          />
          {showIcon && (
            <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center bg-white h-fit top-0 bottom-0 my-auto">
              {icon || (
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

DebouncedInput.displayName = "DebouncedInput";

export default DebouncedInput;

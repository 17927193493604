import { useComplianceInfo } from "hooks/useComplianceInfo";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { componentsStore } from "store/Mapping/ComponentsStore";

const ComplianceOverview = () => {
  const { t } = useTranslation();
  const { getCertificationChipsGroup, getConformityChipsGroup } =
    useComplianceInfo();

  if (!componentsStore.data.compliance) return <></>;
  return (
    <div className="border-b border-gray-200 mb-6 pb-6 last-of-type:border-b-0">
      <div className="text-base font-semibold mb-4">
        {t("productEditor.conformity")}
      </div>
      <div className="text-sm text-gray-700 mb-2">
        {t("components.cetification_desc")}
      </div>
      {getCertificationChipsGroup(
        componentsStore.data.compliance?.compliant_certifications
      )}

      <div className="text-sm text-gray-700 mb-2 mt-4">
        {t("components.conformity_desc")}
      </div>
      {getConformityChipsGroup(componentsStore.data.compliance?.conformities)}
    </div>
  );
};

export default observer(ComplianceOverview);

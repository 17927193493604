import React from "react";

function PdfTemplate() {
  return (
    <iframe
      src="/PDFTemplate/index.html"
      title="HTML App"
      width="100%"
      style={{
        border: "none",
        height: "88vh",
      }}
    ></iframe>
  );
}

export default PdfTemplate;

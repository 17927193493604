import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconGripVertical, IconTrash } from "@tabler/icons-react";
import clsx from "clsx";
import SimpleColumnsMenu from "./SimpleExistingMenu";
import SimpleSelect from "components/SimpleSelect";
import { SORT_OPTIONS } from "../../const";
import { PropertyBrowserTreeNode } from "api-client";
import { SortProps } from ".";
import { useTranslation } from "react-i18next";

const wrapperID = "sort-wrapper";

interface SortDraggbleItemProps {
  id: string;
  index: number;
  row: SortProps;
  items: PropertyBrowserTreeNode[];
  isEng: boolean;
  onChangeProperty: (value: PropertyBrowserTreeNode, index: number) => void;
  onChangeSortOption: (value: string | undefined, index: number) => void;
  removeCurrentRow: (index: number) => void;
  scrollPos: number;
}

const SortDraggbleItem: React.FC<SortDraggbleItemProps> = ({
  id,
  row,
  items,
  isEng,
  index,
  onChangeProperty,
  onChangeSortOption,
  removeCurrentRow,
  scrollPos,
}) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      className={clsx(
        "flex items-center p-2 gap-2 min-w-[400px] hover:bg-indigo-100",
        "transition group/sortItem relative"
      )}
      style={style}
    >
      <IconGripVertical
        className="group-hover/sortItem:text-indigo-700 cursor-grab focus:outline-none"
        {...attributes}
        {...listeners}
      />
      <SimpleColumnsMenu<PropertyBrowserTreeNode>
        isEng={isEng}
        handleSelect={(value) => onChangeProperty(value, index)}
        items={items}
        current={row?.property}
        containerClassName="ml-1 relative"
        wrapperID={wrapperID}
        leftOffset={45}
        parentScrollTop={scrollPos}
        scrollAreaHeight={420}
        placeholder={t("mappingEditor.findProperty")}
      />
      <SimpleSelect
        options={SORT_OPTIONS}
        noMargin
        className="!mb-0 !mt-0"
        onChange={(value) => onChangeSortOption(value, index)}
        current={row?.sort}
      />
      <IconTrash
        className={clsx(
          "cursor-pointer ml-2 min-w-[20px]group-hover/sortItem:text-indigo-700"
        )}
        size={20}
        onClick={() => removeCurrentRow(index)}
      />
    </li>
  );
};

export default SortDraggbleItem;

import React from "react";
import {
  ComponentDetailsByFileOut,
  InventoryProductDetailsByFileOut,
  ProductSearchOutput,
} from "api-client";
import { useTranslation } from "react-i18next";
import { CustomProduct } from "store/Mapping/BuildingProductStore";
import { useNavigate } from "react-router";
import { searchStore } from "store/Mapping/SearchStore";
import { componentsStore } from "store/Mapping/ComponentsStore";
import { SEARCH_TABS_LIST } from "./consts";

export const useMappingTools = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const productAdditionalInfo = (
    product:
      | ProductSearchOutput
      | InventoryProductDetailsByFileOut
      | undefined
      | CustomProduct,
    tags?: boolean
  ) => [
    {
      label: `DIN 276 ${t("mapping.category")}`,
      value: (product as ProductSearchOutput)?.din_category
        ? `${(product as ProductSearchOutput)?.din_category_number ?? ""} ${
            (product as ProductSearchOutput)?.din_category ?? "-"
          }`
        : `${
            (product as InventoryProductDetailsByFileOut)
              ?.din_category_level_1_number ?? ""
          } ${
            (product as InventoryProductDetailsByFileOut)
              ?.din_category_level_1 ?? "-"
          }`,
    },
    {
      label: t("mapping.lifeSpan"),
      value: product?.life_span ?? "-",
    },
    {
      label: t("mapping.isExternal"),
      value:
        typeof product?.is_external === "boolean"
          ? t(`mapping.${String(product?.is_external).toLowerCase()}`)
          : "-",
    },
    {
      label: t("mapping.loadBearing"),
      value:
        typeof product?.is_load_bearing === "boolean"
          ? t(`mapping.${String(product?.is_load_bearing).toLowerCase()}`)
          : "-",
    },
    ...(tags
      ? [
          {
            label: t("mapping.tags_automapper"),
            value:
              (product as InventoryProductDetailsByFileOut)?.automapping_tags
                ?.map((item) => item)
                .join(", ") || "-",
          },
        ]
      : []),
  ];

  const productCircularityInfo = (
    product: ComponentDetailsByFileOut | undefined
  ) => [
    {
      label: t("productEditor.material_origin"),
      value: product?.preuse_qualification,
      score: product?.preuse_score,
    },
    {
      label: t("productEditor.deconstructability"),
      value: product?.deconstructability_qualification,
      score: product?.deconstructability_score,
    },
    {
      label: t("productEditor.separability"),
      value: product?.separability_qualification,
      score: product?.separability_score,
    },
    {
      label: t("mapping.reusePotential"),
      value: product?.reusability_qualification,
      score: product?.reusability_score,
    },
  ];

  function onCloseEditor(tabIndex: number, shouldCloseSearch?: boolean) {
    navigate(-1);
    if (shouldCloseSearch) {
      searchStore.setOpenProductSearch(false);
      componentsStore.setOpenedSearchInCE(false);
    } else {
      searchStore.setSelectedTab(SEARCH_TABS_LIST[tabIndex]);
      searchStore.setOpenProductSearch(true);
    }
  }

  return {
    productAdditionalInfo,
    onCloseEditor,
    productCircularityInfo,
  };
};

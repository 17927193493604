import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import { IconExternalLink } from "@tabler/icons-react";
import { getCurrentReferenceValue } from "../utils";
import { searchStore } from "store/Mapping/SearchStore";

export interface EPDGroupColumns {
  factor?: number | null;
  epd_name: string;
  dataset_source?: string;
  dataset_url?: string;
  recyclability_score?: string | number;
  recyclability_qualification?: string;
  reference_unit?: string;
  reference_size?: string | number;
  material_name?: string;
  conformity?: string;
  mass_value_m?: string | number;
  mass_value_m2?: string | number;
  mass_value_m3?: string | number;
}

const DEFAULT_COLUMNS = [
  "epd_name",
  "factor",
  "dataset_source",
  "material_name",
  "recyclability_score",
  "recyclability_qualification",
  "reference_unit",
  "reference_size",
  "conformity",
  "mass_value_m",
  "mass_value_m2",
  "mass_value_m3",
];

const useDatasetViewTableColumns = (referenceUnit?: string) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const dataSourceName = (source_name: string) =>
    `${source_name.charAt(0).toUpperCase()}${source_name
      .slice(1)
      .toLowerCase()}`;

  function cell(item: EPDGroupColumns, key: string) {
    if (key === "dataset_source" && item.dataset_source !== "-") {
      return (
        <Badge type="auto" className="!h-5" size="small">
          {dataSourceName(String(item.dataset_source))}
          <a
            href={item.dataset_url}
            target="_blank"
            rel={"noreferrer"}
            className="md:flex items-center cursor-pointer"
          >
            <IconExternalLink width={18} className="ml-1" stroke={3} />
          </a>
        </Badge>
      );
    }
    return item[key as keyof EPDGroupColumns];
  }

  function getReferenceUnit(reference_unit: string | undefined) {
    const currentValue = getCurrentReferenceValue(reference_unit);
    return isEng ? currentValue?.name : currentValue?.name_de;
  }

  function columnCell(row: EPDGroupColumns, key: string) {
    if (key === "reference_unit") {
      return <div>{getReferenceUnit(row.reference_unit)}</div>;
    } else if (key === "factor") {
      return `${cell(row, key)} ${getReferenceUnit(
        row.reference_unit
      )}/${getReferenceUnit(
        searchStore.currentDetailNode?.reference_unit ?? referenceUnit
      )}`;
    } else {
      return cell(row, key);
    }
  }

  function column(key: string, index: number) {
    return {
      accessorFn: (row: EPDGroupColumns) => row[key as keyof EPDGroupColumns],
      id: key,
      cell: (info: { row: { original: EPDGroupColumns } }) =>
        columnCell(info.row.original, key),
      header: () => <div className="truncate">{t(`mapping.${key}`)}</div>,
      enableColumnFilter: false,
      enableSorting: false,
      isSticky: index === 0,
      stickyClass: "bg-white shadow-[2px_0_4px_-2px_rgba(0,0,0,0.25)] z-2",
      size: index === 0 || index == 2 ? 150 : 0,
      ...(key === "dataset_source" && { maxWidth: "auto" }),
    };
  }

  return useMemo<ColumnDef<EPDGroupColumns>[]>(
    () => [...DEFAULT_COLUMNS.map((key, index) => column(key, index))],
    []
  );
};

export default useDatasetViewTableColumns;

import React, { useEffect, useRef, useState } from "react";
import { atom } from "recoil";

interface StepperProps {
  length: number;
  onClick: (i: number) => void;
  filledSteps: number[];
  activeStep?: number;
  tabsData?: { label: string }[];
}

export default function Stepper({
  length,
  onClick,
  filledSteps,
  activeStep,
  tabsData = undefined,
}: StepperProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(activeStep ?? 0);

  const tabsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    if (!activeStep) return;
    setActiveTabIndex(activeStep);
  }, [activeStep]);

  return (
    <div className="relative w-full mb-2">
      <hr className="right-0 border-gray-300 absolute h-1 w-full top-[35%] z-0" />
      <div className="flex space-x-3  justify-between">
        {(tabsData ?? Array.from(Array(length).keys())).map((item, i) => {
          const isFilled = filledSteps.includes(i);
          return (
            <button
              key={i}
              disabled={!isFilled}
              ref={(el: HTMLButtonElement) => (tabsRef.current[i] = el)}
              className="pb-3 z-0"
              onClick={() => {
                setActiveTabIndex(i);
                onClick(i);
              }}
            >
              <span
                className={`w-8 h-8 outline outline-8 outline-gray-100 font-normal bg-gray-50  text-sm flex rounded-full items-center justify-center transition-transform cursor-pointer ${
                  i === activeTabIndex
                    ? "bg-white border text-black border-indigo-500"
                    : ""
                } ${isFilled ? "bg-indigo-500" : ""} ${
                  isFilled
                    ? "text-white !bg-indigo-500"
                    : i === activeTabIndex
                    ? "text-black"
                    : "text-gray-400"
                }`}
              >
                {typeof item === "object" ? item.label : item + 1}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}

/* <div className="py-4">
<p>{tabsData[activeTabIndex].content}</p>
</div> */

export const filledStepsAtom = atom<number[]>({
  key: "filledSteps",
  default: [],
});

import MainLayout from "layouts/MainLayout";
import Retool from "pages/Retool";
import { userStore } from "store/UserStore";

const ConcularOne = () => {
  const reportID = "b4b16e7a-d4f9-11ef-807c-c3a2d155cbc9";
  const { userProfile } = userStore;

  const embeddedData = {
    email: userProfile?.email,
    user_id: userProfile?.username,
    user_name: `${userProfile?.first_name} ${userProfile?.last_name}`,
  };

  return (
    <MainLayout className="flex justify-center">
      <div className="w-full">
        <Retool url={reportID} embeddedData={embeddedData} />
      </div>
    </MainLayout>
  );
};

export default ConcularOne;

import DropdownCheckbox from "components/DropdownCheckbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  buildingProductStore,
  Option,
} from "store/Mapping/BuildingProductStore";

export default observer(function EPDTypeFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: Option[];
  setSelected: React.Dispatch<React.SetStateAction<Option[]>>;
  removingItem: Option | undefined;
  handleRemoveItem: () => void;
}) {
  const { t } = useTranslation();

  React.useEffect(() => {
    (async () => {
      await buildingProductStore.fetchEPDtypes();
    })();
  }, []);

  const onChangeCategories = (value: Option[]) => setSelected(value);

  if (!buildingProductStore.filtersOptions.epd_type) return <></>;
  return (
    <DropdownCheckbox<Option>
      displayKey={"name"}
      required={true}
      handleSelect={onChangeCategories}
      labelName={t("mapping.epdType")}
      name="epdType"
      items={buildingProductStore.filtersOptions.epd_type}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[200px] !mb-3"
      optionsClassName="z-40 !max-w-fit"
      checkedItems={selected}
    />
  );
});

import React from "react";
import { MinusCircleIcon } from "@heroicons/react/outline";
import { BlueprintOUT } from "api-client";
import { LocalImage } from "api-client-local/db_interfaces";
import {
  deleteLocalImage,
  getLocalImagesForSource,
  tryGenerateUrlForImage,
} from "api-client-local/images";
import ImageResizer from "../ImageResizer";
import { useTranslation } from "react-i18next";

interface Props {
  blueprint: BlueprintOUT;
  localImages: LocalImage[];
  setLocalImages: React.Dispatch<React.SetStateAction<LocalImage[]>>;
}

export const LocalImages = ({
  blueprint,
  localImages,
  setLocalImages,
}: Props) => {
  const [images, setImages] = React.useState<LocalImage[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    const sorted = [...localImages].sort((a, b) => b.order - a.order);
    setImages(sorted);
  }, [localImages]);

  const onDeleteLocalImage = (imageId: string) => {
    deleteLocalImage(imageId).then(() => {
      getLocalImagesForSource(blueprint.id).then((localImages) => {
        setLocalImages(localImages);
      });
    });
  };

  return (
    <>
      {images.length > 0 && (
        <>
          <p className="font-semibold text-sm">
            {t("audits.localImagesLabel")}
          </p>
          <div>
            {images.map((img, index) => {
              return (
                <div
                  key={index}
                  className="border max-w-[130px] my-3 inline-block mr-3 relative rounded-sm"
                >
                  <ImageResizer
                    width="500"
                    height="500"
                    className="object-cover !w-full !h-[120px] rounded-sm"
                    src={tryGenerateUrlForImage(img) ?? ""}
                    alt={img.id}
                    isLink
                  />
                  <button
                    className="text-white absolute w-5 h-5 -top-1 !z-100 -right-2 bg-red-700 rounded-full"
                    onClick={() => {
                      onDeleteLocalImage(img.id);
                    }}
                  >
                    <MinusCircleIcon />
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

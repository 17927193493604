import { csvBlock, parseCsv } from "@llm-ui/csv";
import { markdownLookBack } from "@llm-ui/markdown";
import { useLLMOutput, type LLMOutputComponent } from "@llm-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
// @ts-ignore
import React, { useEffect, useState } from "react";
import { LoadingView } from "features/Audit/LoadingView";
import { retoolWorkflowsApi, buildingDashboardApi } from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import clsx from "clsx";
import { useSetRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";
import BasePlot from "features/Dashboards/Base/BasePlot";
import { useParams } from "react-router";
import CIrcularLCAIcon from "assets/images/circularLCA_logo-new.svg";
import { IconArrowUp, IconCircle, IconPrinter } from "@tabler/icons-react";
import LogoIcon from "assets/images/logo.svg";
import { useTranslation } from "react-i18next";

const MarkdownComponent: LLMOutputComponent = ({ blockMatch }) => {
  const markdown = blockMatch.output;
  return (
    <ReactMarkdown
      disallowedElements={["code"]}
      className={"markdown"}
      remarkPlugins={[remarkGfm]}
    >
      {markdown}
    </ReactMarkdown>
  );
};

const ChartComponentOptions = {
  type: "charty",
  startChar: "⦅",
  endChar: "⦆",
  delimiter: ",", // the seperator between items
  allIndexesVisible: true,
  visibleIndexes: [],
};

const ChartComponent: LLMOutputComponent = ({ blockMatch }) => {
  if (!blockMatch.isVisible) {
    return null;
  }

  const { id: buildingID } = useParams();
  const settings = parseCsv(blockMatch.output, ChartComponentOptions);
  const [traces, setTraces] = useState<object[]>([]);

  useEffect(() => {
    async function populateChart() {
      const authHeader = await getLocalAuthHeader();
      const chartInput = {
        x_axis: settings[3].trim(),
        y_axis: settings[4].trim(),
      };
      const chart =
        await buildingDashboardApi.reportApiV1RoutersDashboardBuildingDynamicChart(
          buildingID as string,
          chartInput,
          authHeader
        );
      setTraces(chart.data.traces ?? []);
    }
    populateChart();
  }, [settings.join()]);

  return (
    <div className="bg-slate-200 p-4 rounded text-center items-center my-6">
      {!traces && <LoadingView message={""} />}
      {traces && (
        <div>
          <h3 className="font-semibold mb-4">{settings[1]}</h3>
          <BasePlot
            data={traces.map((t) => {
              return { ...t, type: "bar" };
            })}
          />
          <p className="text-gray-900 mt-3">{settings[2]}</p>
        </div>
      )}
    </div>
  );
};

interface AIOutput {
  messages: string[];
}

const getAIResponse = async (
  prompt: string,
  buildingID?: string
): Promise<AIOutput> => {
  const url =
    "https://retool.prod.concular.com/retool/v1/workflows/47790bce-f480-444f-9a19-60dfcbc60b36/startTrigger";
  const input = { user_input: prompt, building_id: buildingID };
  const authHeader = await getLocalAuthHeader();
  const response =
    await retoolWorkflowsApi.retoolApiRouterWorkflowsTriggerWorkflow(
      { endpoint: url, payload: input },
      authHeader
    );
  return response.data.result as AIOutput;
};

const MessagesChat = (props: { message: Message }) => {
  const { blockMatches } = useLLMOutput({
    llmOutput: props.message.text,
    fallbackBlock: {
      component: MarkdownComponent,
      lookBack: markdownLookBack(),
    },
    blocks: [
      {
        ...csvBlock(ChartComponentOptions),
        component: ChartComponent,
      },
    ],
    isStreamFinished: true,
  });

  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  React.useEffect(() => {
    document.addEventListener("DOMContentLoaded", scrollToBottom);
    return () =>
      document.removeEventListener("DOMContentLoaded", scrollToBottom);
  }, []);

  return (
    <div
      className={clsx("flex flex-row pt-6", {
        "justify-end": props.message.type === "Q",
        "justify-start": props.message.type === "A",
      })}
    >
      <div
        className={clsx("rounded-full py-2 mb-4 w-fit", {
          "bg-gray-100 mb-3 px-4": props.message.type === "Q",
        })}
      >
        <div className={`flex items-start ${props.message.type}`}>
          {props.message.type === "A" && (
            <img
              src={LogoIcon}
              alt="concular-logo"
              width={18}
              className="mt-1 mr-2"
            />
          )}
          <div>
            {blockMatches.map((blockMatch, index) => {
              const Component = blockMatch.block.component;
              return <Component key={index} blockMatch={blockMatch} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Prompt = (props: {
  onSubmit: (prompt: string) => void;
  addQuestion: (text: string) => void;
}) => {
  const [textPrompt, setTextprompt] = useState("");
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleKeyDown = React.useCallback((e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      buttonRef.current?.click();
      setTextprompt("");
    }
  }, []);

  React.useEffect(() => {
    inputRef.current?.focus();
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div
      className={clsx(
        "flex flex-row p-3 my-4 gap-4 bg-gray-100 rounded-xl",
        "border border-gray-200 w-full items-center"
      )}
    >
      <input
        className={clsx(
          "placeholder:text-gray-800 w-full bg-transparent border-none",
          "rounded-md py-2 px-2 pr-3 focus:outline-none",
          "focus:ring-1 sm:text-sm"
        )}
        placeholder={t("AI.messageConcularAI")}
        type="text"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setTextprompt(event.target.value)
        }
        value={textPrompt}
        ref={inputRef}
      />
      <button
        className={clsx(
          "flex items-center justify-center gap-x-2 rounded-md bg-indigo-600 text-sm w-8 h-8",
          "font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline whitespace-nowrap",
          "focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        )}
        onClick={() => {
          props.onSubmit(textPrompt);
          props.addQuestion(textPrompt);
        }}
        ref={buttonRef}
      >
        <IconArrowUp />
      </button>
    </div>
  );
};

interface Message {
  type: "Q" | "A";
  text: string;
}
const PageAI = () => {
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);
  const { t } = useTranslation();

  React.useEffect(() => {
    setSidebarOpened(false);
  }, []);

  const [textValues, setTextValues] = useState<Message[]>([]);
  const [isWorking, setIsWorking] = useState(false);
  const { id: buildingID } = useParams();

  React.useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, [textValues.length]);

  const triggerAI = (prompt: string) => {
    setIsWorking(true);
    getAIResponse(prompt, buildingID).then((result) => {
      console.log(result);
      setIsWorking(false);
      result &&
        setTextValues((state) => [
          ...state,
          { type: "A", text: result.messages.join("\n\n\n  \n\n\n") },
        ]);
    });
  };

  function addToQuestions(text: string) {
    setTextValues((prevState) => [...prevState, { type: "Q", text: text }]);
  }

  return (
    <div className="flex flex-col px-6 h-full bg-white min-h-[calc(100vh-100px)] print:min-h-screen">
      <div className="hidden print:block text-xl font-semibold opacity-50 mt-1">
        ConcularAI
      </div>
      <div
        className={clsx("flex-grow", {
          "content-center justify-items-center": !textValues.length,
        })}
      >
        {!textValues.length && (
          <img
            src={CIrcularLCAIcon}
            alt="CIrcularLCA"
            width={300}
            className="opacity-30 items-center "
          />
        )}
        {textValues.map((item, index) => (
          <MessagesChat message={item} key={index} />
        ))}

        {isWorking && (
          <div className="flex items-center">
            <img src={LogoIcon} alt="concular-logo" width={18} className="" />
            <div className="flex gap-1 bg-gray-100 w-fit py-1 px-2 rounded-full ml-3">
              <IconCircle
                stroke={0}
                className="bg-gray-400 border-gray-400 rounded-full w-2 h-2 transition animate-fadeWithDelay0"
              />
              <IconCircle
                stroke={0}
                className="bg-gray-400 border-gray-400 rounded-full w-2 h-2 transition animate-fadeWithDelay1"
              />
              <IconCircle
                stroke={0}
                className="bg-gray-400 border-gray-400 rounded-full w-2 h-2 transition animate-fadeWithDelay2"
              />
            </div>
          </div>
        )}
        {textValues.length ? (
          <div
            className="ml-4 text-gray-500 hover:bg-gray-50 rounded-lg w-fit p-2 cursor-pointer float-right print:hidden"
            onClick={() => window.print()}
          >
            <IconPrinter />
          </div>
        ) : null}
      </div>
      <div className="sticky bottom-0 bg-white flex flex-col w-full items-center print:hidden">
        {!textValues.length && (
          <div className="font-semibold">{t("AI.whatCanIHelp")}</div>
        )}
        <Prompt
          onSubmit={triggerAI}
          addQuestion={(text) => addToQuestions(text)}
        />
      </div>
    </div>
  );
};

export default PageAI;

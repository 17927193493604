import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading";
import ChooseCertSystem from "./steps/Step1";
import ChooseDGNBVersion from "./steps/Step2";
import ConfigureDGNBVersion from "./steps/Step3";
import AdditionalInfo from "./steps/Step4";
import CreateExcelFile from "./steps/Step5";
import Button from "components/Button";
import { certExportStore } from "store/CertExportStore";
import Alert from "components/Alert";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { CERT_EXPORT_ALERT_URL } from "utils";
import clsx from "clsx";
import { buildingStore } from "store/Building/BuildingStore";
import { useNavigate } from "react-router";
import { dynamicMEStore } from "store/Mapping/DMEStore";

const CertificateExporter = observer(
  ({
    open,
    setOpen,
  }: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const { t } = useTranslation();
    const [stepTitle, setStepTitle] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    const { activeStep, certExporter } = certExportStore;

    React.useEffect(() => {
      onLoad();
      return () => certExportStore.setActiveStep(0);
    }, []);

    React.useEffect(() => {
      getStepTitles();
    }, [activeStep]);

    async function onLoad() {
      certExportStore.resetData();
      const inProgressItems =
        await buildingStore.getBuildingExportCertifications("IN_PROGRESS");
      certExportStore.setExportIsInProgress(
        inProgressItems ? inProgressItems?.length > 0 : false
      );
      await dynamicMEStore.fetchCertificationData();
      setLoading(false);
    }

    function getStepTitles() {
      switch (activeStep) {
        case 0:
          return setStepTitle(t("certificationExport.step1Title"));
        case 1:
          return setStepTitle(t("certificationExport.step2Title"));
        case 2:
          return setStepTitle(t("certificationExport.step3Title"));
        case 3:
          return setStepTitle(t("certificationExport.step4Title"));
        case 4:
          return setStepTitle("");
        default:
          return setStepTitle("");
      }
    }

    const renderStepContent = () => {
      switch (activeStep) {
        case 0:
          return <ChooseCertSystem />;
        case 1:
          return <ChooseDGNBVersion />;
        case 2:
          return <ConfigureDGNBVersion />;
        case 3:
          return <AdditionalInfo />;
        case 4:
          return <CreateExcelFile onFinish={() => setOpen(false)} />;
        default:
          return null;
      }
    };

    const isStep3orGreater = activeStep > 1;
    const handleNext = () => {
      if (activeStep === 2) {
        certExportStore.setActiveStep(activeStep + 2);
        return;
      }
      certExportStore.setActiveStep(activeStep + 1);
    };
    const handleBack = () => {
      if (
        activeStep === 1 ||
        (activeStep === 3 && certExporter.certSystem?.includes("QNG"))
      ) {
        certExportStore.setActiveStep(0);
        return;
      }

      if (activeStep === 4 && certExporter.dgnbVersion?.includes("2018")) {
        certExportStore.setActiveStep(2);
        return;
      }

      if (activeStep === 4) {
        certExportStore.setActiveStep(activeStep - 1);
        return;
      }

      if (certExporter.dgnbVersion?.includes("2023")) {
        certExportStore.setActiveStep(activeStep - 2);
        return;
      }

      if (certExporter.certSystem?.includes("QNG")) {
        certExportStore.setActiveStep(activeStep - 3);
      } else {
        certExportStore.setActiveStep(activeStep - 1);
      }
    };

    const navigateToExports = () => {
      navigate("exports");
      setOpen(false);
    };

    function content(): React.ReactNode {
      if (certExportStore.exportIsInProgress) {
        return (
          <Alert
            type="info"
            title={t("certificationExport.inProgressAlertTitle")}
            titleClassName="pt-0.5 font-semibold"
            actionButton={t("certificationExport.inProgressAlertButton")}
            actionButtonOnClick={navigateToExports}
            className="m-6"
          />
        );
      }
      return (
        <div className={clsx({ "flex flex-col p-6": activeStep !== 4 })}>
          <Heading className="text-base font-semibold mb-4" size="subtitle">
            {stepTitle}
          </Heading>
          {renderStepContent()}
          {isStep3orGreater && activeStep < 4 && (
            <Alert
              className="mt-3"
              description={t("certificationExport.alertMessage")}
              actionButton={t("certificationExport.alertButtonText")}
              type="info"
              actionButtonOnClick={() =>
                window.open(
                  CERT_EXPORT_ALERT_URL,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            />
          )}
        </div>
      );
    }

    return (
      <Modal
        isOpen={open}
        setIsOpen={setOpen}
        closeButton
        title={t("certificationExport.title")}
        disableOutsideClick
        containerClassName="sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl"
      >
        {loading ? skeleton() : content()}
        {certExportStore.shouldShowBackButton && (
          <div className="bg-gray-50 p-4 mt-2 flex flex-row justify-between">
            <Button
              leadingIcon={<ArrowLeftIcon />}
              type="gray"
              width="fit-content"
              onClick={handleBack}
            >
              {t("DGNB.back")}
            </Button>
            {isStep3orGreater && activeStep < 4 && (
              <Button type="primary" width="fit-content" onClick={handleNext}>
                {t("commons.next")}
              </Button>
            )}
          </div>
        )}
      </Modal>
    );
  }
);

export default CertificateExporter;

function skeleton() {
  return (
    <div className="animate-pulse m-6">
      <div className="h-24 w-full bg-gray-200 rounded dark:bg-gray-700"></div>
    </div>
  );
}

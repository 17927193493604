import React from "react";
import { ManufacturerOUT } from "api-client";
import DropdownCheckbox from "components/DropdownCheckbox";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { searchStore } from "store/Mapping/SearchStore";

export default observer(function ManufacturerFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: ManufacturerOUT[];
  setSelected: React.Dispatch<React.SetStateAction<ManufacturerOUT[]>>;
  removingItem: ManufacturerOUT | undefined;
  handleRemoveItem: () => void;
}) {
  const { t } = useTranslation();

  const onChange = (value: ManufacturerOUT[]) => setSelected(value);

  React.useEffect(() => {
    (async () => {
      const items = await dynamicMEStore.getManufacturers();
      items &&
        searchStore.setFilterOptions({
          ...searchStore.filtersOptions,
          manufacturers: items,
        });
    })();
  }, []);

  if (!searchStore.filtersOptions.manufacturers) return <></>;
  return (
    <DropdownCheckbox<ManufacturerOUT>
      displayKey={"name"}
      required={true}
      handleSelect={onChange}
      labelName={`${t("mapping.publisher")}/${t("mapping.manufacturer")}`}
      name="manufacturer"
      items={searchStore.filtersOptions.manufacturers}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[200px] !mb-3"
      optionsClassName="z-40"
      checkedItems={selected}
    />
  );
});
